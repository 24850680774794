import { Link } from 'react-router-dom';
import styles from '../styles/pages/Dashboard.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import MapPinIcon from '../assets/icons/location-pin-dashboard.svg';
import AppointmentsIcon from '../assets/icons/appointments.svg'
import MemberIcon from '../assets/icons/become-a-member.svg'
import QuestionMarkIcon from '../assets/icons/question-intake-form.svg';
import ArrowIcon from '../assets/icons/arrow-down.svg';
import GiftCardsIcon from '../assets/icons/gift-cards.svg'
import ClockIcon from '../assets/icons/clock.svg'
import HandIcon from '../assets/icons/massage-therapist.svg'
import { useToast } from '../components/core/ToastManager';
import GiftCardBalance from '../components/GiftCardBalance';
import Spinner from '../components/core/Spinner';
import MembershipCredits from '../components/core/MembershipCredits';
import { DashboardCard } from '../components/DashboardCard';
import { numberToText } from '../utils/caseConverter';
import { useUserAppointments } from '../hooks/useAppointments';
import { useUpdateUserBoutique, useUserMemberships, useUserProfile } from '../hooks/useUserProfile';
import { useBoutique } from '../hooks/useBoutiques';
import { formatPhoneNumber } from '../utils/formatPhone';
import { LocationPicker } from '../components/core/LocationPicker';
import { useState } from 'react';
import LocationSearch from '../components/LocationSearch';
import { Boutique } from '../types/boutiques';
import ErrorScreen from '../components/core/ErrorMessages';
import Modal from '../components/core/Modal';
import PreferencesModal from '../components/Preferences';

export const Dashboard = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const [showLocationSearch, setShowLocationSearch] = useState(false);
  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile()
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(userProfile?.data.boutiqueId || '')
  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);
  const { data: appointments, isLoading: isLoadingAppointments, error: appointmentsError } = useUserAppointments()
  const membership = userMemberships?.membershipGroups && userMemberships?.membershipGroups.length > 0 
    ? userMemberships.membershipGroups.find((m) => m.userMemberships.find((um) => um.status !== 'cancelled' && um.status !== 'closed' && um.status !== 'expired'))?.userMemberships[0].membership
    : null;
  const membershipGroup = userMemberships?.membershipGroups ? userMemberships.membershipGroups[0] : null;
  const membershipDescription = membership 
    ? `${numberToText(membership.frequencyPerMonth)} ${membership.durationInMinutes} minute ${membership.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month` 
    : 'Commit to self-care and save on 50 or 80 minutes of relaxation every month.';
  const membershipCredits = membership?.creditBalance || null
  const creditsName = membershipGroup?.name || null
  const [showPreferencesModal, setShowPreferencesModal] = useState(false)

  const updateBoutiqueMutation = useUpdateUserBoutique();

  const handleBoutiqueChange = async (boutique: string | Boutique) => {
    const boutiqueId = typeof boutique === 'string' ? boutique : boutique.id;
    try {
      await updateBoutiqueMutation.mutateAsync(boutiqueId);
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      queryClient.invalidateQueries({ queryKey: ['boutique', { boutique_id: userProfile?.data.boutiqueId }] });
      addToast('Boutique updated successfully', 'success');
    } catch (error) {
      addToast('Failed to update boutique. Please try again.', 'error');
    }
  };

  const nextAppointment = appointments?.futureAppointments && appointments.futureAppointments[0]

  if (isLoadingUserProfile) return <Spinner />
  if (userProfileError && !isLoadingUserProfile) return <ErrorScreen errors={[userProfileError]} />

  return (
    <div className={styles.dashboard}>
      <h1>Welcome back{userProfile?.data.firstName ? `, ${userProfile.data.firstName}!` : "!"}</h1>
      <div className={styles.cardGrid}>
        <DashboardCard iconUrl={AppointmentsIcon} title="Upcoming Appointment">
          {isLoadingAppointments ? (
            <Spinner />
          ) : (
            <div className={styles.upcomingAppointmentContent}>
              <div className={styles.upcomingAppointmentInfo}>
                {nextAppointment ? (
                  <>
                    <p className={styles.appointmentDate}>
                      {DateTime.fromISO(nextAppointment.date).toFormat('MMMM dd')}
                    </p>
                    <div className={styles.appointmentTime}>
                      <img src={ClockIcon} alt="clock" />
                      <p>{nextAppointment.time}</p>
                    </div>
                    <div className={styles.appointmentBoutique}>
                      <img src={MapPinIcon} alt="location pin" />
                      <p className={styles.appointmentLocation}>{nextAppointment.boutiqueName}</p>
                    </div>
                    <div className={styles.appointmentDetails}>
                      <img src={AppointmentsIcon} alt="globe" />
                      <div className={styles.appointmentAddOns}>
                        <p>{nextAppointment.serviceName}</p>
                        {nextAppointment.addOnPackages?.length > 0 && (
                          <p>
                            {nextAppointment.addOnPackages.map((addOn, index) => (
                              <span key={index}>
                                + {addOn.name}
                                {index < nextAppointment.addOnPackages.length - 1 && ', '}
                              </span>
                            ))}
                          </p>
                        )}
                        {nextAppointment.therapistName && (
                          <p>Therapist, {nextAppointment.therapistName}</p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <p className={styles.noAppointments}>No upcoming appointments.</p>
                )}
              </div>
              <div className={styles.appointmentCtas}>
                <Link
                  className={`button ${styles.cta} ${nextAppointment ? 'inverted' : ''}`}
                  to={nextAppointment ? '/appointments' : '/book'}
                >
                  {nextAppointment ? 'Manage Appointment' : 'Book an Appointment'}
                </Link>
                {nextAppointment && (
                  <Link to="/appointments" className="button__underline">
                    VIEW ALL
                  </Link>
                )}
              </div>
            </div>
          )}
          {appointmentsError && !isLoadingAppointments && (
            <ErrorScreen errors={[appointmentsError]} />
          )}
        </DashboardCard>

        <DashboardCard 
          iconUrl={MemberIcon} 
          title={membership ? "Your Ritual Membership" : "Become a Ritual Member"}
        >
          {isLoadingUserMemberships ? (
            <Spinner />
          ) : (
            <div className={styles.memberCardContent}>
              {membership ? (
                <div>
                  {membershipGroup && (
                    <p className={styles.membershipSubtitle}>{membershipGroup.name}</p>
                  )}
                  <ul className={styles.membershipDetails}>
                    <li>{membershipDescription}</li>
                    <li>
                      <p className={styles.membershipLocation}>
                        {membership.boutique.name}
                      </p>
                    </li>
                  </ul>
                  {membershipCredits && creditsName && (
                    <MembershipCredits name={creditsName} amount={membershipCredits} />
                  )}
                </div>
              ) : (
                <p>{membershipDescription}</p>
              )}
              <div className={styles.membershipCta}>
                <Link
                  className={`button ${membership ? 'inverted' : ''} ${styles.cta}`}
                  to="/settings/memberships"
                >
                  {membership ? "Manage Membership" : "Join Now"}
                </Link>
                {/* the line below is for alignment with appointment block, a bit tricky, but didn't find better solution */}
                {nextAppointment && <div className={styles.alignmentBlock}></div>}
              </div>

            </div>
          )}
          {userMembershipsError && !isLoadingUserMemberships && (
            <ErrorScreen errors={[userMembershipsError]} />
          )}
        </DashboardCard>

        <DashboardCard 
          iconUrl={MapPinIcon}
          title="Your Boutique"
          >
          {isLoadingBoutique ? <Spinner /> : (
            <div className={styles.locationContainer}>
              <div className={styles.locationPickerContainer}>
                <LocationPicker currentBoutique={boutique?.data.id || ''} onBoutiqueChange={handleBoutiqueChange} />
              </div>
              <div className={styles.boutiqueCardLocation} onClick={() => setShowLocationSearch(true)} >
                <h4>{boutique?.data.name}</h4>
                <p>{boutique?.data.address} {boutique?.data.address2}, {boutique?.data.city}, {boutique?.data.state} {boutique?.data.zip}</p>
                <img className={styles.arrowIcon} src={ArrowIcon} alt="arrow" />
              </div>
            </div>
          )}
          {boutiqueError && <p className={styles.error}>Failed to load boutique information</p>}
        </DashboardCard>
        <DashboardCard iconUrl={GiftCardsIcon} title="Gift Card Balance">
          <div className={styles.giftCardContent}>
            <p>Enter the gift card code to check the balance.</p>
            <GiftCardBalance />
            <Link className={`button__underline ${styles.purchaseGiftCardButton}`} to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/giftcards`}>
              Purchase Gift Card
            </Link>
          </div>
        </DashboardCard>
        {/* DO NOT DELETE, WILL UNCOMMENT LATER */}
        {/* <DashboardCard iconUrl={QuestionMarkIcon} title="In-Take Questionnaire">
          <div className={styles.questionnaireCardContent}>
            <p>Please complete the required in-take questionnaire prior to arriving for a massage.</p>
            <Link className={`button inverted ${styles.cta}`} to="/questionnaire">
              Complete Questionnaire
            </Link>
          </div>
        </DashboardCard> */}
        {/* <DashboardCard iconUrl={HandIcon} title="Personal Preferences">
          <div className={styles.questionnaireCardContent}>
            <p>Are there areas that you would like your therapist to focus on or avoid?</p>
            <button className={`button inverted ${styles.cta}`} onClick={() => setShowPreferencesModal(true)}>
              Complete personal preferences
            </button>
          </div>
        </DashboardCard> */}
        <DashboardCard title="Welcome to our Elevated New Site!">
          <div className={styles.newSiteCard}>
            <p>If you experience any issues or need assistance with accessing your account or booking, please contact the boutique.</p>
            <Link className="button inverted w-full" to={`tel:${boutique?.data.phoneNumber}`}>call {formatPhoneNumber(boutique?.data.phoneNumber || '')}</Link>
          </div>
        </DashboardCard>
        {showLocationSearch && 
          <LocationSearch
            currentBoutique={boutique?.data || null}
            setBoutique={handleBoutiqueChange}
            onClose={() => setShowLocationSearch(false)} 
          />
        }
        {showPreferencesModal && (
          <Modal onClose={() => setShowPreferencesModal(false)} isOpen={showPreferencesModal} className={styles.preferencesModal}>
            <PreferencesModal onClose={() => setShowPreferencesModal(false)} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Dashboard;