import { useEffect, useState } from 'react';
import Modal from './core/Modal';
import { useToast } from './core/ToastManager';
import styles from '../styles/components/AddPaymentMethodModal.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import { useCreatePaymentProfile } from '../hooks/usePaymentProfile';
import Spinner from './core/Spinner';
import { PaymentProfileRequest } from '../types/payment';
import { pushToDataLayer } from '../utils/tracking';

interface AddPaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddPaymentMethodModal: React.FC<AddPaymentMethodModalProps> = ({
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { mutate: createPaymentProfile, data, error, isPending } = useCreatePaymentProfile();
  const [responseSuccess, setResponseSuccess] = useState(false);

  useEffect(() => {
    if (!isOpen) return

    const paymentProfileRequest: PaymentProfileRequest = {
      platform: 'web',
      redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/add-card-success`,
    };
    createPaymentProfile(paymentProfileRequest, {
      onSuccess: (response) => {
        setResponseSuccess(response.data.success)
      },
      onError: (error) => {
        addToast(error.errorMessages[0] || 'Error fetching Payment Profile', 'error');
      }
    });
  }, [isOpen])

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data?.type === 'processComplete') {
        if (event.data.success) {
          onClose(); 
          addToast(event.data.message, 'success');
          pushToDataLayer({ 
            event: 'paymentInfo',
            action: 'buttonClick'
          });
          queryClient.invalidateQueries({ queryKey: ['creditCards'] });
        } else {
          addToast(event.data.message, 'error');
          onClose();
        }
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return (
    <Modal className={`${styles.iframeModal} ${!responseSuccess ? styles.error : ''}`} isOpen={isOpen} onClose={onClose}>
      {isPending ? <Spinner /> : !error && responseSuccess ? (
        <iframe src={data?.data.hostedPaymentUri} title="Payment Method" className={styles.iframe} />
      ): (
        <div className={styles.limitError}>
          <p>Sorry, you’ve reached the limit for adding a credit card. Please try again in 1 hour.</p>
          <button className={`button`} onClick={onClose}>Close</button>
        </div>
      )}
    </Modal>
  );
};

export default AddPaymentMethodModal
