import { useState } from 'react'
import { AutoCompleteInput } from './AutoCompleteInput'
import { useBoutiques } from '../../hooks/useBoutiques';

interface Props {
  currentBoutique: string
  onBoutiqueChange: (boutiqueId: string) => void
  locked?: boolean
}

export const LocationPicker:React.FC<Props> = ({ currentBoutique, onBoutiqueChange, locked }) => {
  const { data: boutiques } = useBoutiques({ perPage: 100, page: 1 });
  const [location, setLocation] = useState(currentBoutique)

  const formattedBoutiques = boutiques?.data.boutiques
  .filter(b => b.useOldSite === false)
  .map(boutique => ({
    value: boutique.id,
    label: boutique.name
  }));

  const handleLocationChange = async (loc: string) => {
    setLocation(loc)
    onBoutiqueChange(loc)
  };

  return (
    <div className={locked ? 'locked' : ''}>
      <AutoCompleteInput
        label="Update Location"
        id="change-location"
        options={formattedBoutiques || []}
        value={location}
        onChange={handleLocationChange}
        onBlur={() => {}}
        className="location-picker"
      />
    </div>
  )
}
