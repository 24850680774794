import { useEffect } from "react";
import styles from "../styles/pages/PurchaseConfirmation.module.scss"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUserProfile } from "../hooks/useUserProfile";
import { useBoutique } from "../hooks/useBoutiques";
import GiftCardDetails from "../components/GiftCardDetails";
import ErrorScreen from "../components/core/ErrorMessages";
import Spinner from "../components/core/Spinner";


export const CustomGiftCardPurchaseConfirmation = () => {
  const { boutiqueId } = useParams();
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message')
  const recipientName = searchParams.get('recipientName')
  const giftCardValue = searchParams.get('custom_amount')
  const generalPromoValue = Number(searchParams.get('general_promo_value')) || 0
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfile();
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')
  const promotionalValue = Number(searchParams.get('promo_value')) || 0
  const navigate = useNavigate();

  useEffect(() => {
    if (!boutiqueId) {
      navigate('/dashboard');
    }
  }, [])

  if (userProfileLoading || isLoadingBoutique) {
    return <Spinner />
  }

  if (boutiqueError) {
    return <ErrorScreen errors={[boutiqueError ?? undefined]} />
  }

  return (
    <div className={styles.purchaseConfirmation}>
       <div className={styles.orderDetails}>
        <h3>Order Details</h3>
        <GiftCardDetails 
          title={`$${giftCardValue} Gift Card`}
          promotionalValue={promotionalValue}
          location={boutique?.data.name || ''}
          message={message || ''}
          senderName={`${userProfile?.data.firstName} ${userProfile?.data.lastName}`}
          recipientName={recipientName || ''}
          hideBreadcrumb
          generalPromoValue={generalPromoValue}
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.message}>
        <h3>Thank you, {userProfile?.data.firstName}.</h3>
        <p>A copy of your invoice with gift card details has been sent to: {userProfile?.data.email}</p>
        <Link to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/giftcards`} className={`button`}>Buy Another Gift Card</Link>
        <Link to="/dashboard" className={`button inverted`}>Back to Dashboard</Link>
      </div>
    </div>
  )
}

export default CustomGiftCardPurchaseConfirmation