import { PromoDetailsResponse, SignInPromotionResponse } from './../types/boutiques';
import { get } from './apiClient';
import { BoutiquesResponse, Boutique, BoutiquesRequestParams, PromotionResponse } from '../types/boutiques';

export const fetchBoutiques = async (options: BoutiquesRequestParams = {}): Promise<BoutiquesResponse> => {
  const params: Record<string, any> = {};

  // Only add properties to params if they are defined
  if (options.lat !== undefined) params.lat = options.lat;
  if (options.lng !== undefined) params.lng = options.lng;
  if (options.ip !== undefined) params.ip = options.ip;
  if (options.perPage !== undefined) params.per_page = options.perPage;
  if (options.page !== undefined) params.page = options.page;

  const response = await get<BoutiquesResponse>('/api/v1/boutiques', { params });

  return response.data;
};

export const fetchBoutiqueById = async (id: string): Promise<{ data: Boutique }> => {
  const response = await get<{ data: Boutique }>(`/api/v1/boutiques/${id}`);
  
  return response.data;
};

export const fetchBoutiquePromotions = async (boutiqueId: string, promoType: string): Promise<PromotionResponse> => {
  const response = await get<PromotionResponse>(`/api/v1/boutiques/${boutiqueId}/promotions/${promoType}`);

  return response.data;
}

export const fetchNationalPromotions = async (promoType: string): Promise<PromotionResponse> => {
  const response = await get<PromotionResponse>(`/api/v1/boutiques/national_promotions/${promoType}`);

  return response.data;
}

export const fetchSignInPromotions = async (): Promise<SignInPromotionResponse> => {
  const response = await get<SignInPromotionResponse>(`/api/v1/sign_in_promotions`);

  return response.data;
}

export const fetchPromoDetails = async (id: string, boutiqueId: string, serviceId: string, bookingDate?: string, addOnIds?: string[]): Promise<PromoDetailsResponse> => {
  const addOnIdsString = addOnIds ? addOnIds.join(',') : '';
  const addOnsQuery = addOnIdsString ? `&add_on_ids=${addOnIdsString}` : '';
  const response = await get<PromoDetailsResponse>(`/api/v1/boutiques/${boutiqueId}/promo_codes/${id}?booking_date=${bookingDate}&service_id=${serviceId}${addOnsQuery}`);
  
  return response.data;
};

export const fetchMembershipPromoDetails = async (id: string, boutiqueId: string): Promise<PromoDetailsResponse> => {
  const response = await get<PromoDetailsResponse>(`/api/v1/boutiques/${boutiqueId}/membership_promo_codes/${id}`);
  
  return response.data;
};
