import { useEffect } from "react";
import styles from "../styles/pages/PurchaseConfirmation.module.scss"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUserProfile } from "../hooks/useUserProfile";
import { useBoutique } from "../hooks/useBoutiques";
import { useGiftCardTemplates } from "../hooks/useGiftCards";
import GiftCardDetails from "../components/GiftCardDetails";
import ErrorScreen from "../components/core/ErrorMessages";
import Spinner from "../components/core/Spinner";


export const GiftCardPurchaseConfirmation = () => {
  const { boutiqueId, templateId } = useParams();
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message')
  const recipientName = searchParams.get('recipientName')
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfile();
  const { data: giftCardTemplates, isLoading: isLoadingGiftCardTemplates, error: giftCardTemplatesError } = useGiftCardTemplates(boutiqueId || '')
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')
  const selectedGiftCard = giftCardTemplates?.templates.find(giftCard => giftCard.zenotiId === templateId)
  const promotionalValue = selectedGiftCard && selectedGiftCard?.value - selectedGiftCard?.price
  const navigate = useNavigate();
  const generalPromoValue = giftCardTemplates?.generalPromoValue || 0

  useEffect(() => {
    if (!boutiqueId || !templateId) {
      navigate('/dashboard');
    }
  }, [])

  if (isLoadingGiftCardTemplates || userProfileLoading || isLoadingBoutique) {
    return <Spinner />
  }

  if (giftCardTemplatesError || boutiqueError) {
    return <ErrorScreen errors={[giftCardTemplatesError ?? undefined, boutiqueError ?? undefined]} />
  }

  return (
    <div className={styles.purchaseConfirmation}>
       <div className={styles.orderDetails}>
        <h3>Order Details</h3>
        <GiftCardDetails 
          title={`$${selectedGiftCard?.price} Gift Card`}
          promotionalValue={promotionalValue}
          location={boutique?.data.name || ''}
          message={message || ''}
          senderName={`${userProfile?.data.firstName} ${userProfile?.data.lastName}`}
          recipientName={recipientName || ''}
          hideBreadcrumb
          generalPromoValue = {generalPromoValue}
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.message}>
        <h3>Thank you, {userProfile?.data.firstName}.</h3>
        <p>A copy of your invoice with gift card details has been sent to: {userProfile?.data.email}</p>
        <Link to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/giftcards`} className={`button`}>Buy Another Gift Card</Link>
        <Link to="/dashboard" className={`button inverted`}>Back to Dashboard</Link>
      </div>
    </div>
  )
}

export default GiftCardPurchaseConfirmation