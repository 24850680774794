import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/components/GiftCardBalance.module.scss';
import { Input } from '../components/core/Input';
import { useState } from 'react';
import { useCheckGiftCardBalance } from '../hooks/useGiftCards';
import Spinner from './core/Spinner';

interface FormData {
  giftCardCode: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  giftCardCode: yup.string().required('Gift Card code is required'),
});

const GiftCardBalance: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isValid }, setError, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const [showBalance, setShowBalance] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { mutate, data, isPending } = useCheckGiftCardBalance();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    mutate(data.giftCardCode, {
      onSuccess: () => {
        setShowBalance(true);
      },
      onError: (error) => {
        setShowBalance(false);
        setError('giftCardCode', {
          type: 'manual',
          message: error.errorMessages[0] || 'Code is invalid, please try again.',
        });
      }
    });
  };

  const checkAnotherCard = () => {
    setShowBalance(false);
    reset({ giftCardCode: '' });
  };

  if (data && showBalance) {
    return (
      <div>
        <div className={styles.balance}>
          <p>Your card balance: ${data.data.balance.toFixed(2)}</p>
        </div>
        <button onClick={checkAnotherCard} className={`button ${styles.button}`}>Check another card</button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Input
        id="giftCardCode"
        label={isFocused ? 'Gift Card Code' : 'Type Gift Card Code Here'}
        inputProps={{ 
          ...register('giftCardCode'),
          type: 'text',
          autoComplete: 'off',
        }}
        error={errors.giftCardCode?.message}
        apiError={errors.giftCardCode?.type === 'manual' ? true : false}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}

      />
      <button
        // className={`button ${styles.button} ${!isValid ? "disabled" : ""}`}
        className="button w-full"
        disabled={!isValid}
        type="submit"
      >
        {isPending ? <Spinner size={20} /> : 'Check Balance'}
      </button>
    </form>
  );
};

export default GiftCardBalance;
