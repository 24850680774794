import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthHeaders } from '../../types/auth';

interface AuthContextType {
  isAuthenticated: boolean;
  authHeaders: AuthHeaders | null;
  login: (headers: AuthHeaders) => void;
  logout: () => void;
  updateAuthHeaders: (headers: AuthHeaders) => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  authHeaders: null,
  login: () => {},
  logout: () => {},
  updateAuthHeaders: () => {},
  loading: true,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authHeaders, setAuthHeaders] = useState<AuthHeaders | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    try {
      const storedAuth = localStorage.getItem('auth');
      if (storedAuth) {
        const parsedAuth = JSON.parse(storedAuth) as AuthHeaders;
        setAuthHeaders(parsedAuth);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Error parsing auth from localStorage', error);
      setAuthHeaders(null);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const login = (headers: AuthHeaders) => {
    localStorage.setItem('auth', JSON.stringify(headers));
    setAuthHeaders(headers);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const logout = () => {
    window.location.href = '/login';
    localStorage.removeItem('auth');
    setAuthHeaders(null);
    setIsAuthenticated(false);
    setLoading(false);
  };

  const updateAuthHeaders = (headers: AuthHeaders) => {
    localStorage.setItem('auth', JSON.stringify(headers));
    setAuthHeaders(headers);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, authHeaders, login, logout, updateAuthHeaders, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
