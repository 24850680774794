import { get, post, put } from './apiClient';
import { 
  ServicesResponse,
  TherapistsResponse,
  CreateServiceBookingRequest,
  CreateServiceBookingResponse,
  ConfirmServiceBookingRequest,
  ConfirmServiceBookingResponse,
  UpdateServiceBookingRequest,
  UpdateServiceBookingResponse,
  CreateGuestsRequest,
  CreateGuestsResponse,
  EnhancementsResponse,
  ConfirmUnauthenticatedServiceBookingRequest
} from '../types/bookings';


export const fetchServices = async (boutiqueId: string): Promise<ServicesResponse> => {
  const response = await get<ServicesResponse>(`api/v2/boutiques/${boutiqueId}/services`);
  
  return response.data;
};

export const fetchTherapists = async (boutiqueId: string): Promise<TherapistsResponse> => {
  const response = await get<TherapistsResponse>(`api/v1/boutiques/${boutiqueId}/therapists`);
  
  return response.data;
};

export const createServiceBooking = async (
  boutiqueId: string, 
  data: CreateServiceBookingRequest
): Promise<CreateServiceBookingResponse> => {
  const response = await post<CreateServiceBookingResponse, CreateServiceBookingRequest>(
    `api/v1/boutiques/${boutiqueId}/create_service_booking`,
    data
  );
  
  return response.data;
};

export const getTherapistAvailabilities = async (
  boutiqueId: string,
  data: CreateServiceBookingRequest
): Promise<CreateServiceBookingResponse> => {

  const guestQueries = data.guests
    .map((guest, index) => {
      const guestIndex = index + 1;
      return [
        `guest_${guestIndex}_service_id=${guest.serviceId}`,
        `guest_${guestIndex}_add_on_ids=${guest.addOnIds.join(",")}`,
        `guest_${guestIndex}_therapist_id=${guest.therapistId}`,
        `guest_${guestIndex}_therapist_gender=${guest.therapistGender}`
      ].join("&");
    })
    .join("&");

  const queryString = `date=${data.date}&no_of_guests=${data.guests.length}&${guestQueries}`;

  const response = await get<CreateServiceBookingResponse>(
    `api/v1/boutiques/${boutiqueId}/therapist_availabilities?${queryString}`
  );

  return response.data;
};


export const confirmServiceBooking = async (
  boutiqueId: string, 
  data: ConfirmServiceBookingRequest
): Promise<ConfirmServiceBookingResponse> => {
  const response = await post<ConfirmServiceBookingResponse, ConfirmServiceBookingRequest>(
    `api/v1/boutiques/${boutiqueId}/confirm_service_booking`,
    data
  );
  
  return response.data;
};

export const confirmUnauthenticatedServiceBooking = async (
  boutiqueId: string, 
  data: ConfirmUnauthenticatedServiceBookingRequest
): Promise<ConfirmServiceBookingResponse> => {
  const response = await post<ConfirmServiceBookingResponse, ConfirmUnauthenticatedServiceBookingRequest>(
    `api/v2/boutiques/${boutiqueId}/service_bookings`,
    data
  );
  
  return response.data;
};

export const updateServiceBooking = async (
  boutiqueId: string, 
  data: UpdateServiceBookingRequest
): Promise<UpdateServiceBookingResponse> => {
  const response = await put<UpdateServiceBookingResponse, UpdateServiceBookingRequest>(
    `api/v1/boutiques/${boutiqueId}/update_service_booking`,
    data
  );
  
  return response.data;
};

export const createGuests = async (
  boutiqueId: string, 
  data: CreateGuestsRequest
): Promise<CreateGuestsResponse> => {
  const response = await post<CreateGuestsResponse, CreateGuestsRequest>(
    `api/v1/boutiques/${boutiqueId}/guests`,
    data
  );
  
  return response.data;
};

export const fetchEnhancements = async (
  boutiqueId: string
): Promise<EnhancementsResponse> => {
  const response = await get<EnhancementsResponse>(`api/v1/boutiques/${boutiqueId}/enhancements`);
  
  return response.data;
};