import { useState } from 'react';
import InfoIcon from '../../assets/icons/info.svg';
import styles from '../../styles/components/MembershipCredits.module.scss';
import Modal from './Modal';

interface props {
  name?: string;
  amount: number;
}

export const MembershipCredits:React.FC<props> = ({ name, amount }) => {
  const [showCreditsInfoModal, setShowCreditsInfoModal] = useState(false);
  return (
    <div className={styles.membershipCredits}>
        <div className={`${styles.membershipCreditsItem} ${amount > 0 ? styles.filled : ''}`} >
          <p>
            {name} Credits:
            <span>{amount}</span>
          </p>
          <button className={styles.creditsInfoButton} onClick={() => setShowCreditsInfoModal(true)}>
            <img src={InfoIcon} alt="Info Icon" />
          </button>
        </div>
      <Modal
        isOpen={showCreditsInfoModal}
        onClose={() => setShowCreditsInfoModal(false)}
        className={styles.creditsModal}
      >
        <div className={styles.creditsModalContent}>
          <h2 className={styles.creditsModalTitle}>Ritual Membership Credits</h2>
          <ul>
            <li>Your membership credit balance includes all upcoming appointments. Credits are deducted when your booking is made.</li>
            <li>Should you cancel your appointment 12 hours before your appointment, any unused credits will be reflected in your available credits.</li>
          </ul>
          <button className={`button large inverted ${styles.creditsModalButton}`} onClick={() => setShowCreditsInfoModal(false)}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default MembershipCredits