import { post, del, put } from './apiClient';
import { InvoiceRequest, InvoiceResponse, GiftCardCloseInvoiceRequest, ApplyPromoToInvoiceResponse } from '../types/purchase';

export const createInvoice = async (
  membershipData: InvoiceRequest
): Promise<InvoiceResponse> => {
  const response = await post<InvoiceResponse, InvoiceRequest>('/api/v1/user/invoices', membershipData);
  return response.data;
};

export const closeInvoice = async (
  invoiceId: string,
  giftCardPurchase?: boolean,
  boutiqueId?: string
): Promise<InvoiceResponse> => {
  const response = await del<InvoiceResponse, { giftCardPurchase?: boolean }>(
    `/api/v1/user/invoices/${invoiceId}`,
    { 
      ...(giftCardPurchase && {giftCardPurchase}),
      ...(boutiqueId && {boutiqueId})
    }
  );
  return response.data;
};

export const closeGiftCardInvoice = async (
  invoiceId: string,
  data: GiftCardCloseInvoiceRequest
): Promise<InvoiceResponse> => {
  const response = await del<InvoiceResponse, GiftCardCloseInvoiceRequest>(
    `/api/v1/user/invoices/${invoiceId}`,
    data
  );
  return response.data;
};

export const applyPromoToInvoice = async (
  invoiceId: string,
  offerCode: string,
  boutiqueId: string
): Promise<ApplyPromoToInvoiceResponse> => {
  const response = await put<ApplyPromoToInvoiceResponse, { offerCode: string, boutiqueId: string }>(
    `/api/v1/user/invoices/${invoiceId}`,
    {offerCode, boutiqueId}
  );
  return response.data;
};