import { useQuery, useMutation } from '@tanstack/react-query';
import {
  fetchServices,
  fetchTherapists,
  createServiceBooking,
  confirmServiceBooking,
  updateServiceBooking,
  createGuests,
  fetchEnhancements,
  getTherapistAvailabilities,
  confirmUnauthenticatedServiceBooking,
} from '../api/bookings';
import {
  ServicesResponse,
  TherapistsResponse,
  CreateServiceBookingRequest,
  CreateServiceBookingResponse,
  ConfirmServiceBookingRequest,
  ConfirmServiceBookingResponse,
  UpdateServiceBookingRequest,
  UpdateServiceBookingResponse,
  CreateGuestsRequest,
  CreateGuestsResponse,
  EnhancementsResponse,
  ConfirmUnauthenticatedServiceBookingRequest,
} from '../types/bookings';
import { ApiErrorResponse } from '../types/api';


export const useServices = (boutiqueId: string) => {
  return useQuery<ServicesResponse, ApiErrorResponse>({
    queryKey: ['services', boutiqueId],
    queryFn: () => fetchServices(boutiqueId),
    enabled: !!boutiqueId && boutiqueId !== 'null',
  });
};

export const useTherapists = (boutiqueId: string) => {
  return useQuery<TherapistsResponse, ApiErrorResponse>({
    queryKey: ['therapists', boutiqueId],
    queryFn: () => fetchTherapists(boutiqueId),
    enabled: !!boutiqueId && boutiqueId !== 'null',
  });
};

export const useCreateServiceBooking = (
  onSuccess?: (data: CreateServiceBookingResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<CreateServiceBookingResponse, ApiErrorResponse, { boutiqueId: string; data: CreateServiceBookingRequest }>({
    mutationFn: ({ boutiqueId, data }) => createServiceBooking(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useGetTherapistAvailabilities = (
  onSuccess?: (data: CreateServiceBookingResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<CreateServiceBookingResponse, ApiErrorResponse, { boutiqueId: string; data: CreateServiceBookingRequest }>({
    mutationFn: ({ boutiqueId, data }) => getTherapistAvailabilities(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useConfirmServiceBooking = (
  onSuccess?: (data: ConfirmServiceBookingResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<ConfirmServiceBookingResponse, ApiErrorResponse, { boutiqueId: string; data: ConfirmServiceBookingRequest }>({
    mutationFn: ({ boutiqueId, data }) => confirmServiceBooking(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useConfirmUnauthenticatedServiceBooking = (
  onSuccess?: (data: ConfirmServiceBookingResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<ConfirmServiceBookingResponse, ApiErrorResponse, { boutiqueId: string; data: ConfirmUnauthenticatedServiceBookingRequest }>({
    mutationFn: ({ boutiqueId, data }) => confirmUnauthenticatedServiceBooking(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useUpdateServiceBooking = (
  onSuccess?: (data: UpdateServiceBookingResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<UpdateServiceBookingResponse, ApiErrorResponse, { boutiqueId: string; data: UpdateServiceBookingRequest }>({
    mutationFn: ({ boutiqueId, data }) => updateServiceBooking(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useCreateGuests = (
  onSuccess?: (data: CreateGuestsResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<CreateGuestsResponse, ApiErrorResponse, { boutiqueId: string; data: CreateGuestsRequest }>({
    mutationFn: ({ boutiqueId, data }) => createGuests(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useEnhancements = (boutiqueId: string) => {
  return useQuery<EnhancementsResponse, ApiErrorResponse>({
    queryKey: ['enhancements', boutiqueId],
    queryFn: () => fetchEnhancements(boutiqueId),
    enabled: !!boutiqueId,
  });
};
