import styles from '../styles/components/BookingDetails.module.scss'
import MapPinIcon from '../assets/icons/location-pin-dashboard.svg'
import GiftCardIcon from '../assets/icons/gift-cards.svg'
import AppointmentsIcon from '../assets/icons/appointments.svg'
import EnvelopeIcon from '../assets/icons/envelope.svg'
import MessageIcon from '../assets/icons/message.svg'
import PeopleIcon from '../assets/icons/people.svg'
import Arrow from '../assets/icons/arrow-down.svg'
import { Link } from 'react-router-dom'

interface OrderDetailsProps {
  title: string;
  message?: string;
  location: string;
  promotionalValue?: number;
  recipientName: string;
  recipientEmail?: string;
  senderName: string;
  hideBreadcrumb?: boolean;
  alternateEmail?: string;
  generalPromoValue: number;
}

export const GiftCardDetails: React.FC<OrderDetailsProps> = ({
  title,
  message,
  location,
  promotionalValue,
  recipientName,
  senderName,
  hideBreadcrumb,
  recipientEmail,
  alternateEmail,
  generalPromoValue
}) => {
  return (
    <div className={`${styles.bookingDetails}`}>
      {!hideBreadcrumb && 
      <>
        <div className={styles.navigation}>
          <img src={Arrow} alt="Back Arrow" />
          <Link className="button__link" to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/giftcards`}>Back to Gift Card Options</Link>
        </div>
        <div className={styles.separator}></div>
      </>
      }
      <div className={styles.bookingInfo}>
        <div className={styles.item}>
          <img src={GiftCardIcon} alt="Gift Card icon" />
          <div>
            <p>{title}</p>
            {promotionalValue && promotionalValue > 0 && generalPromoValue > 0 && (<p className={styles.promotionalValue}>+ ${promotionalValue} {(promotionalValue / generalPromoValue) > 1 ? "in promotional cards" : "promotional card"}</p>)}
          </div>
        </div>
        <div className={styles.item}>
          <img src={MapPinIcon} alt="Map Pin Icon" />
          <p>Redeemable at: {location}</p>
        </div>
        {!recipientEmail && <div className={styles.item}>
          <img src={EnvelopeIcon} alt="Envelope Icon" />
          <div className={styles.itemInfo}>
            <p>Delivery Method: Digital</p>
          </div>
        </div>}
        <div className={styles.item}>
          <img src={PeopleIcon} alt="People Icon" />
          <div className={styles.itemInfo}>
            <p>To: {recipientName}</p>
            <p>From: {senderName}</p>
          </div>
        </div>
        {message && 
          <div className={`${styles.item} ${styles.message}`}>
            <img src={MessageIcon} alt="Message" />
            <div>
              <p>Optional Message</p>
              <p className={styles.messageContent}>{message}</p>
            </div>
          </div>
        }
        {(recipientEmail || alternateEmail) && promotionalValue && promotionalValue > 0 ? (
          <div className={styles.item}>
            <img src={AppointmentsIcon} alt="Globe Icon" />
            <div className={styles.itemInfo}>
              <p>Promo Cards Delivered to:</p>
              <p>{alternateEmail || recipientEmail}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default GiftCardDetails