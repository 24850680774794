import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/BookingCheckout.module.scss';
import { Checkbox } from '../components/core/Checkbox';
import AddPaymentMethodModal from '../components/AddPaymentMethodModal';
import Modal from '../components/core/Modal';
import Spinner from '../components/core/Spinner';
import { PaymentMethodModal } from '../components/PaymentMethodModal';
import { useToast } from '../components/core/ToastManager';
import { useCreditCards } from '../hooks/useCreditCards';
import BookingDetails from '../components/BookingDetails';
import { useBoutique, usePromoDetails } from '../hooks/useBoutiques';
import { useConfirmServiceBooking, useConfirmUnauthenticatedServiceBooking, useServices, useTherapists } from '../hooks/useBookings';
import { DateTime } from 'luxon';
import PlusIcon from '../assets/icons/plus.svg'
import CrossIcon from '../assets/icons/cross.svg'
import MassageIcon from '../assets/icons/appointments.svg'
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import PreferenceIcon from '../assets/icons/massage-therapist.svg'
import ActiveSelectIcon from '../assets/icons/active-select.svg';
import InactiveSelectIcon from '../assets/icons/inactive-select.svg';
import ErrorScreen from '../components/core/ErrorMessages';
import { Input } from '../components/core/Input';
import { useCheckGiftCardBalance } from '../hooks/useGiftCards';
import { useCreateUserMembership, useUserMemberships, useUserProfile } from '../hooks/useUserProfile';
import { pushToDataLayer } from '../utils/tracking';
import { useMemberships } from '../hooks/useMemberships';
import { useCloseInvoice, useCreateInvoice } from '../hooks/useInvoice';
import { useQueryClient } from '@tanstack/react-query';
import MembershipTermsModal from '../components/MembershipTermsModal';

interface GuestData {
  name: string;
  serviceId: string;
  zenotiId: string;
  addOnIds: string[];
  therapistId?: string;
  therapistGender: string;
}

export const BookingCheckout = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { bookingId, boutiqueId, serviceId } = useParams();
  const [searchParams] = useSearchParams()
  const time = searchParams.get('time')
  const therapistIdQuery = searchParams.get('therapistId')
  const therapistId = therapistIdQuery !== 'undefined' ? therapistIdQuery : undefined
  const minorHostGender = searchParams.get('gender') !== 'undefined' ? searchParams.get('gender') : undefined
  const encodedGuests = searchParams.get('guests');
  const encodedAddOns = searchParams.get('addOns')
  const createMembership = searchParams.get('createMembership')
  const formattedDate = time ? DateTime.fromISO(time).toFormat("MMM dd") : null
  const formattedTime = time ? DateTime.fromISO(time).toFormat("hh:mm a") : null
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')
  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError} = useUserProfile()
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useServices(boutiqueId || '')
  const { data: therapists, isLoading: isLoadingTherapists, error: therapistsError } = useTherapists(boutiqueId || '');
  const { data: userMemberships, isLoading: isLoadingUserMemberships } = useUserMemberships(true);
  const selectedService = services?.services.find(service => service.zenotiId === serviceId)
  const selectedDuration = services?.durations.find(duration => duration.serviceDurationMinutes === selectedService?.duration)
  const validUserMembership = userMemberships?.membershipGroups.flatMap(group => group.userMemberships).find(membership => (membership.membership.durationInMinutes === selectedDuration?.displayDurationMinutes) && membership.status === 'active')
  const selectedTherapist = therapists?.therapists.find(therapist => therapist.zenotiId === therapistId)
  const [notes, setNotes] = useState('')
  const [groupName, setGroupName] = useState('')
  const [expandNotes, setExpandNotes] = useState(false)
  const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
  const [expandedGuestPriceIds, setExpandedGuestPriceIds] = useState<string[]>([]);
  const {mutate: confirmBooking, isPending: isConfirmBookingPending, error: confirmBookingError} = useConfirmServiceBooking(
    (data) => {
      if (data.success) {
        console.log('booking invoice id:', data.data.invoiceId, 'booking invoice item id:', data.data.invoiceItemId)
        clearBookingDataFromSessionStorage()
        addToast('Your booking confirmed!', 'success')
        pushToDataLayer({
          event: 'tnm.booking',
          workflowName: 'booking',
          action: 'buttonClick',
          'booking.location': boutique?.data.name,
          locationName: boutique?.data.name
        })
        pushToDataLayer({
          event: 'bookingSuccess',
          workflowName: 'booking',
          action: 'buttonClick',
          'booking.location': boutique?.data.name,
          locationName: boutique?.data.name
        })
        const membershipPurchasedQuery = createMembership && selectedPrice === 'member' ? `&membershipPurchased=true` : ''
        navigate(`/booking-confirmation/${boutiqueId}/${serviceId}?time=${time}&therapistId=${therapistId}&addOns=${encodedAddOns}${membershipPurchasedQuery}`)
      }
      if (data.error) {
        console.log(data.error)
      }
    },
    (error) => {
      addToast(`Failed to confirm booking: ${error.errorMessages[0]}`, 'error');
    }
  );
  const {mutate: confirmUnauthenticatedBooking, isPending: isConfirmUnauthenticatedBookingPending, error: confirmUnauthenticatedBookingError} = useConfirmUnauthenticatedServiceBooking(
    (data) => {
      if (data.success) {
        console.log('booking invoice id:', data.data.invoiceId, 'booking invoice item id:', data.data.invoiceItemId)
        clearBookingDataFromSessionStorage()
        addToast('Your booking confirmed!', 'success')
        pushToDataLayer({
          event: 'tnm.booking',
          workflowName: 'booking',
          action: 'buttonClick',
          'booking.location': boutique?.data.name,
          locationName: boutique?.data.name
        })
        pushToDataLayer({
          event: 'bookingSuccess',
          workflowName: 'booking',
          action: 'buttonClick',
          'booking.location': boutique?.data.name,
          locationName: boutique?.data.name
        })
        const membershipPurchasedQuery = createMembership && selectedPrice === 'member' ? `&membershipPurchased=true` : ''
        navigate(`/booking-confirmation/${boutiqueId}/${serviceId}?time=${time}&therapistId=${therapistId}&addOns=${encodedAddOns}${membershipPurchasedQuery}`)
      }
      if (data.error) {
        console.log(data.error)
      }
    },
    (error) => {
      addToast(`Failed to confirm booking: ${error.errorMessages[0]}`, 'error');
    }
  );

  let addOnIds: string[] = [];
    if (encodedAddOns && encodedAddOns !== 'undefined') {
        try {
          addOnIds = JSON.parse(decodeURIComponent(encodedAddOns));
        } catch (error) {
            console.error("Failed to parse IDs:", error);
        }
    }

  let guestsData: GuestData[] = []

  if (encodedGuests) {
    try {
      guestsData = JSON.parse(decodeURIComponent(encodedGuests));
    } catch (error) {
      console.error("Failed to parse guests data:", error);
    }
  }

  const selectedAddOns = services?.services.filter(service => addOnIds.includes(service.zenotiId))

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [promoError, setPromoError] = useState('');
  const [giftCardCode, setGiftCardCode] = useState('');
  const [isGiftCardApplied, setIsGiftCardApplied] = useState(false);
  const [giftCardError, setGiftCardError] = useState('');
  const [selectedPrice, setSelectedPrice] = useState(createMembership ? 'member' : '')
  const [expandMembershipDetails, setExpandMembershipDetails] = useState(false)
  const [showMembershipTermsModal, setShowMembershipTermsModal] = useState(false);

  const { mutate: validatePromo, data: promoData, isPending: isPromoPending } = usePromoDetails(
    (promoDetails) => {
      if (promoDetails.data.valid) {
        setIsPromoApplied(true);
        addToast('Promo code applied successfully!', 'success');
        pushToDataLayer({
          event: 'promoCodeAdded',
          workflowName: 'booking',
          action: 'buttonClick',
        })
      } else {
        setPromoError('Invalid promo code');
        setIsPromoApplied(false);
      }
    },
    (error) => {
      setPromoError(error?.errorMessages?.[0] || 'Error validating promo code');
      setIsPromoApplied(false);
    }
  );

  const { mutate: checkGiftCardBalance, data: giftCardBalance, isPending: isGiftCardBalanceCheckPending } = useCheckGiftCardBalance(
    (data) => {
      setIsGiftCardApplied(true)
      pushToDataLayer({
        event: 'giftCardRedemption',
        workflowName: 'booking',
        action: 'buttonClick',
      })
    },
    (error) => {
      setGiftCardError(error?.errorMessages?.[0] || 'Error checking gift card balance');
      setIsGiftCardApplied(false)
    }
  );

  const [checkbox1, setCheckbox1] = useState(false); 

  const { data: creditCards, error: creditCardError, isLoading: isCreditCardLoading } = useCreditCards();
  const [selectedCard, setSelectedCard] = useState(creditCards?.data[0])
  const clearBookingDataFromSessionStorage = () => {
    sessionStorage.removeItem('bookingData');
  };

  const toggleGuestDetails = (id: string) => {
    setExpandedGuestPriceIds((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const { data: boutiqueMemberships, isLoading: isLoadingMemberships, error: membershipsError } = useMemberships(boutiqueId);
  const membershipToPurchase = boutiqueMemberships?.membershipGroups
    .flatMap((group) => group.memberships) // Flatten all memberships into a single array
    .find((membership) => membership.durationInMinutes === selectedDuration?.displayDurationMinutes);

  useEffect(() => {
    if (!boutiqueId || !time || !bookingId) {
      navigate('/book');
    }
    sessionStorage.setItem('unauthCheckoutLink', '')
  }, []);

  const handlePromoApply = () => {
    if (promoCode.trim() === '') {
      setPromoError('Promo code cannot be empty');
      return;
    }
    setPromoError('');
    validatePromo({promoCode, boutiqueId: boutiqueId || '', serviceId: selectedService?.zenotiId || '', bookingDate: time || '', addOnIds});
  };

  const deletePromoCode = () => {
    setIsPromoApplied(false)
    setPromoCode('')
  }
  const handleGiftCardApply = () => {
    if (giftCardCode.trim() === '') {
      setGiftCardError('Gift Card code cannot be empty');
      return;
    }
    setGiftCardError('');
    checkGiftCardBalance(giftCardCode);
  };
  
  const deleteGiftCardCode = () => {
    setIsGiftCardApplied(false);
    setGiftCardCode('');
  };
  

  const totalHostAddOnsPrice = selectedAddOns?.reduce((sum, item) => sum + item.salePrice, 0)
  const memberPrice = (selectedService?.salePrice || 0) - (selectedService?.memberSaving || 0);
  const nonMemberPrice = selectedService?.salePrice || 0
  const totalHostPrice = validUserMembership || selectedPrice === 'member'
    ? memberPrice + (totalHostAddOnsPrice || 0)
    : nonMemberPrice + (totalHostAddOnsPrice || 0);
  const totalGuestPrice = guestsData.reduce((sum: number, guest: GuestData) => {
    const guestSelectedService = services?.services.find(service => service.zenotiId === guest.serviceId);
    const guestServicePrice = (guestSelectedService?.salePrice || 0);
  
    // Calculate add-ons price for the current guest
    const guestAddOnsPrice = services?.services
      .filter(service => guest.addOnIds.includes(service.zenotiId))
      .reduce((addOnSum, addOn) => addOnSum + addOn.salePrice, 0) || 0;
  
    return sum + guestServicePrice + guestAddOnsPrice;
  }, 0);

  const totalPrice = totalHostPrice + totalGuestPrice
  const isPayNowDisabled = !checkbox1 || creditCards?.data.length === 0 || (guestsData.length > 0 && groupName.trim() === '');

  const handleConfirmBooking = () => {
    const promoDetails = isPromoApplied && promoData?.data.promoValue
    ? ` Applied Promo: ${promoData?.data.code}: -$${promoData?.data.promoValue}: ${promoData?.data.codeType}.`
    : '';
  const giftCardDetails = isGiftCardApplied
    ? ` Applied Gift Card: Code: ${giftCardCode}, amount: $${giftCardBalance?.data.balance}.`
    : '';

    if (bookingId === "null") {
      const guestsAndHostData = guestsData.map((guest) => {
        return {
          serviceId: guest.serviceId,
          zenotiId: guest.zenotiId,
          addOnIds: guest.addOnIds,
          therapistId: guest.therapistId && guest.therapistId !== 'undefined' ? guest.therapistId : '',
          therapistGender: guest.therapistGender
        }
      })
      guestsAndHostData.unshift({
        serviceId: serviceId || '',
        zenotiId: userProfile?.data.zenotiId || '',
        addOnIds: addOnIds,
        therapistId: therapistId || '',
        therapistGender: selectedTherapist?.gender || minorHostGender || 'any'
      })
      confirmUnauthenticatedBooking({
        data: {
        time: time || '',
        groupName: groupName || 'Members Portal Booking',
        notes: notes + promoDetails + giftCardDetails,
        guests: guestsAndHostData
      }, boutiqueId: boutiqueId || ''
      })
      return
    } else {
      confirmBooking({
        data: {
        bookingId: bookingId || '',
        time: time || '',
        groupName: groupName || 'Members Portal Booking',
        notes: notes + promoDetails + giftCardDetails,
      }, boutiqueId: boutiqueId || ''
      })
    }
  }

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 50) return;
    setGroupName(e.target.value);
  }

  const promoValue = promoData?.data.promoValue || 0
  const giftCardBalanceAmount = giftCardBalance?.data.balance || 0;

  const calculateAdjustedTotalPrice = () => {
    let promoDiscount = 0;
  
    if (isPromoApplied && promoValue) {
      promoDiscount = promoData?.data.discountType === 'percentage_off'
        ? totalPrice * (promoValue / 100)
        : promoValue;
    }
  
    const giftCardDiscount = isGiftCardApplied ? giftCardBalanceAmount : 0;
  
    // Final price ensuring it's not negative
    return Math.max(totalPrice - promoDiscount - giftCardDiscount, 0);
  };
  
  const adjustedTotalPrice = calculateAdjustedTotalPrice();

  const managePaymentMethod = () => {
    setShowPaymentMethodModal(true);
  }

  // membership purchase flow
  const [isCardVerificationRequired, setIsCardVerificationRequired] = useState(false);
  const [openIframeModal, setOpenIframeModal] = useState(false);

  const { mutate: createInvoice, data: invoiceData, isPending: isCreateInvoicePending, error: createInvoiceError } = useCreateInvoice(
    (data) => {
      if (data.data.status !== 'Open') {
        addToast(`Purchase failed due to technical issues. Please try again later.`, 'error');
      } else {
        if (creditCards?.data[0].accountId) {
          createUserMembership({
            redirectUri: `${process.env.REACT_APP_WEBSITE_URL}/purchase-success`,
            invoiceId: data.data.invoiceId,
            accountId: selectedCard?.accountId || creditCards?.data[0].accountId,
            boutiqueId: boutiqueId || ''
          });
        } else {
          addToast(`Purchase failed: No credit card on file. Please add a credit card to proceed.`, 'warning');
        }
      }
    },
    (error) => {
      addToast(`Purchase failed: ${error.errorMessages[0]}`, 'error');
    }
  );

  const { mutate: createUserMembership, data: createMembershipData, isPending: isCreateMembershipPending, error: createMembershipError } = useCreateUserMembership(
    (data) => {
      if (!data.data.success) {
        setIsCardVerificationRequired(true);
        setOpenIframeModal(true);
      } else if (data.data.success && invoiceData?.data.invoiceId) {
        closeInvoice({invoiceId: invoiceData?.data.invoiceId, giftCardPurchase: false, boutiqueId: boutiqueId});
      }
    },
    (error) => {
      addToast(`Error creating membership: ${error.errorMessages[0]}`, 'error');
    }
  );

  const { mutate: closeInvoice, data: closeInvoiceData, isPending: isCloseInvoicePending, error: closeInvoiceError } = useCloseInvoice(
    (data) => {
      if (data.data.status !== 'Closed') {
        addToast(`Error purchasing membership`, 'error');
      } else {
        queryClient.invalidateQueries({ queryKey: ['userMemberships'] });
          addToast('Purchase successful!', 'success', 3500);
          handleConfirmBooking();
          pushToDataLayer({
            event: 'tnm.purchase',
            membershipData: `Membership name: ${membershipToPurchase?.name}, Duration: ${membershipToPurchase?.durationInMinutes} minutes`,
            workflowName: 'booking'
          })
      }
    },
    (error) => {
      addToast(`Error closing invoice: ${error.errorMessages[0]}`, 'error');
    }
  );

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data?.type === 'processComplete' && invoiceData?.data.invoiceId) {
        if (event.data.success) {
          setOpenIframeModal(false);
          closeInvoice({invoiceId: invoiceData?.data.invoiceId, giftCardPurchase: false, boutiqueId: boutiqueId});
        } else {
          addToast(event.data.message, 'error');
        }
      }
    };
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [invoiceData?.data.invoiceId]);

  const handleMembershipPurchase = () => {
    createInvoice({ membershipId: membershipToPurchase?.zenotiId || '', boutiqueId: boutiqueId });
  }
  const handleBooking = () => {
    if (!validUserMembership && selectedPrice === 'member' && membershipToPurchase) {
      handleMembershipPurchase();
    } else {
      handleConfirmBooking();
    }
  }

  const isPurchasePending = isCreateInvoicePending || isCreateMembershipPending || isCloseInvoicePending;

  if (isLoadingBoutique || isLoadingServices || isLoadingTherapists) return <Spinner />;
  if (boutiqueError || servicesError || therapistsError) return <ErrorScreen errors={[boutiqueError ?? undefined, servicesError ?? undefined, therapistsError ?? undefined]} />;

  return (
    <div className={styles.bookingCheckout}>
      <div className={styles.bookingDetails}>
        <h2>Appointment Details</h2>
        <BookingDetails 
          title={selectedService?.name || ''}
          location={boutique?.data.name || ''}
          locationId={boutiqueId}
          therapistName={selectedTherapist?.nickName || ''}
          date={formattedDate || ''}
          time={formattedTime || ''}
          addOns={selectedAddOns?.map(addOn => addOn.name) || []}
        />
      </div>

      <div className={styles.paymentDetails}>
        {guestsData.length > 0 && (
          <div className={styles.contentBlock}>
            <h2>Name Your Group</h2>
            <p>Please reference your group name if you have questions before your appointment or when you arrive for your appointment</p>
            <div>
              <Input id="group-name" label="Name Your Group" inputProps={{value: groupName, onChange: handleGroupNameChange}} />
              <p className={styles.charactersLimit}>{50 - groupName.length} characters remaining</p>
            </div>
          </div>
        )}
        {createMembership && !validUserMembership && (
          <div className={styles.contentBlock}>
            <h2>Choose Your Price</h2>
            <div className={styles.chooseYourPrice}>
              <div className={`${styles.priceOptionContainer} ${selectedPrice === 'member' ? styles.selectedPrice : ''}`}>
                <div className={styles.priceOption}>
                  <img
                    className={styles.radioBox}
                    onClick={() => setSelectedPrice('member')}
                    src={selectedPrice === 'member' ? ActiveSelectIcon : InactiveSelectIcon}
                    alt="radio box"
                  />
                  <div className={styles.memberPriceDetails}>
                    <div className={styles.priceDetails}>
                      <p>Member Price</p>
                      <p>${memberPrice}</p>
                    </div>
                    <div className={styles.membershipDetails}>
                      <div onClick={() => setExpandMembershipDetails((prev => !prev))} className={`${styles.memberPriceToggleDetails} ${expandMembershipDetails ? styles.expanded : ''}`}>
                        <p>Monthly Membership Details</p>
                        <img src={ArrowDownIcon} alt="arrow down" />
                      </div>
                      <p>per month</p>
                    </div>
                  </div>
                </div>
                {expandMembershipDetails && 
                  <div className={styles.membershipDetailsContent}>
                    <p>By checking the box above you agree to the Terms and Conditions of the Now Massage Membership agreement. <button onClick={() => setShowMembershipTermsModal(true)} className='button__link'>View Membership Agreement</button></p>
                  </div>
                }
              </div>
              <div className={`${styles.priceOptionContainer} ${selectedPrice === 'no-member' ? styles.selectedPrice : ''}`}>
                <div className={styles.priceOption}>
                  <img
                      className={styles.radioBox}
                      onClick={() => setSelectedPrice('no-member')}
                      src={selectedPrice === 'no-member' ? ActiveSelectIcon : InactiveSelectIcon}
                      alt="radio box"
                    />
                  <div className={styles.priceDetails}>
                    <p>Non-Member Price</p>
                    <p>${nonMemberPrice}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.contentBlock}>
          <h2>Payment Method</h2>
          {isCreditCardLoading ? (
            <Spinner />
          ) : creditCards?.data && creditCards?.data.length > 0 ? (
            <div className={styles.inputWithAction}>
              <p><strong>{selectedCard?.cardLogo || creditCards.data[0].cardLogo}</strong> ending in {selectedCard?.lastFour || creditCards.data[0].lastFour}</p>
              <button className={`button__underline`} onClick={managePaymentMethod}>MANAGE</button>
            </div>
          ) : (
            <button className="button" onClick={managePaymentMethod}>Add Payment Method</button>
          )}
          {guestsData.length === 0 && (
          <div className={styles.inputWithAction}>
            <input
              className={styles.promoInput}
              placeholder="Add promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button className="button__underline" onClick={handlePromoApply}>APPLY</button>
          </div>
          )}
          {promoError && <p className={styles.promoError}>{promoError}</p>}
          {isPromoApplied && <p className={styles.promoSuccess}>Promo code applied!</p>}
          
          <div className={styles.inputWithAction}>
            <input
              className={styles.promoInput}
              placeholder="Add gift card"
              value={giftCardCode}
              onChange={(e) => setGiftCardCode(e.target.value)}
            />
            <button className="button__underline" onClick={handleGiftCardApply}>APPLY</button>
          </div>
          {giftCardError && <p className={styles.promoError}>{giftCardError}</p>}
          {isGiftCardApplied && <p className={styles.promoSuccess}>Gift card balance applied!</p>}

          {guestsData.length > 0 && (
            <div className={styles.groupPromoDisclaimer}>
              <p>Promotional offer codes must be presented in boutique to redeem for group bookings.</p>
            </div>
          )}
        </div>

        <div className={styles.separator}></div>
        <div className={styles.priceDetailsGroup}>
          <h2>Price Details</h2>
          <div 
            onClick={() => toggleGuestDetails('host')}
            className={`${styles.expandingGuestBlock} ${expandedGuestPriceIds.includes('host') ? styles.expanded : ''}`}
            >
            <p className={styles.guestName}>Your Total</p>
            <div className={styles.expandingContentRight}>
              <p>${totalHostPrice}</p>
              <img src={ArrowDownIcon} alt="Toggle price details" />
            </div>
          </div>
          {expandedGuestPriceIds.includes('host') && (
          <div className={`${styles.contentBlock} ${styles.subContentBlock}`}>
            <div className={styles.priceDetails}>
              <div className={styles.packageInfo}>
                <img src={MassageIcon} alt="insignia" />
                <p>{selectedService?.name}</p>
              </div>
              <div className={styles.price}>${validUserMembership || selectedPrice === 'member' ? memberPrice : nonMemberPrice}</div>
            </div>
            <div className={styles.addOns}>
              {selectedAddOns?.map(addOn => (
                <div className={styles.addOn} key={addOn.id}>
                  <p>+ {addOn.name}</p>
                  <p>${addOn.salePrice}</p>
                </div>
              ))}
            </div>
            <div className={styles.therapistInfo}>
              <img src={PreferenceIcon} alt="Therapist" />
              <p>Therapist, {selectedTherapist?.nickName || selectedTherapist?.gender || minorHostGender || "any"}</p>
            </div>
          </div>
          )}

          {guestsData.length > 0 &&
          guestsData.map((guest: GuestData, index: number) => {
            const guestSelectedService = services?.services.find(service => service.zenotiId === guest.serviceId)
            const selectedAddOns = services?.services.filter(service => guest.addOnIds.includes(service.zenotiId))
            const guestTotalPrice = guestSelectedService?.salePrice && selectedAddOns ? guestSelectedService?.salePrice + selectedAddOns.reduce((sum, item) => sum + item.salePrice, 0) : 0
            const guestSelectedTherapist = guest.therapistId && guest.therapistId !== 'undefined' ? therapists?.therapists.find(t => t.id === guest.therapistId) : undefined
            return (
              <div key={guest.name + index}>
                <div
                  onClick={() => toggleGuestDetails(guest.name + index)}
                  className={`${styles.expandingGuestBlock} ${expandedGuestPriceIds.includes(guest.name + index) ? styles.expanded : ''}`}
                >
                  <p className={styles.guestName}>{guest.name}'s Total</p>
                  <div className={styles.expandingContentRight}>
                    <p>${guestTotalPrice}</p>
                    <img src={ArrowDownIcon} alt="Toggle price details" />
                  </div>
                </div>
  
                {expandedGuestPriceIds.includes(guest.name + index) && (
                  <div className={`${styles.contentBlock} ${styles.subContentBlock}`}>
                    <div className={styles.priceDetails}>
                      <div className={styles.packageInfo}>
                        <img src={MassageIcon} alt="insignia" />
                        <p>{guestSelectedService?.name}</p>
                      </div>
                      <div className={styles.price}>${(guestSelectedService?.salePrice || 0)}</div>
                    </div>
                    <div className={styles.addOns}>
                      {selectedAddOns?.map(addOn => (
                        <div className={styles.addOn} key={addOn.id}>
                          <p>+ {addOn.name}</p>
                          <p>${addOn.salePrice}</p>
                        </div>
                      ))}
                    </div>
                    <div className={styles.therapistInfo}>
                      <img src={PreferenceIcon} alt="Therapist" />
                      <p>Therapist, {guestSelectedTherapist?.nickName || guest.therapistGender || "any"}</p>
                    </div>
                  </div>
                )}
              </div>
            )
          })}

          <div className={styles.separator}></div>

          <div className={`${styles.contentBlock} ${styles.subContentBlock}`}>
            <div className={styles.subTotal}>
              <p>Sub Total</p>
              <p>${totalPrice}</p>
            </div>
            {isPromoApplied && promoCode && (
              <div className={`${styles.subTotal} ${styles.promo}`}>
                <p className={styles.promoCodeCopy}>{promoCode}</p>
                <div className={styles.promoValue}>
                  <p>
                    {promoData?.data.discountType === 'percentage_off'
                      ? `-${promoValue}% (-$${((totalPrice * promoValue) / 100).toFixed(2)})`
                      : `-$${promoValue}`}
                  </p>
                  <button className="button__link" onClick={() => deletePromoCode()}>
                    Remove
                  </button>
                </div>
              </div>
            )}
            {isGiftCardApplied && giftCardCode && (
              <div className={`${styles.subTotal} ${styles.promo}`}>
                <p className={styles.promoCodeCopy}>{giftCardCode}</p>
                <div className={styles.promoValue}>
                  <p>-${giftCardBalance?.data.balance}</p>
                  <button className="button__link" onClick={() => deleteGiftCardCode()}>Remove</button>
                </div>
              </div>
              )}
            <div className={styles.total}>
              <p><strong>Total</strong></p>
              <p><strong>${adjustedTotalPrice.toFixed(2)}</strong></p>
            </div>
            {guestsData.length > 0 && <p className={styles.groupDisclaimer}>Please note, group bookings require a 50% deposit.</p>}
          </div>

        </div>
        
        <div className={styles.separator}></div>

        <div className={styles.notes}>
          <div onClick={() => setExpandNotes(prev => !prev)} className={styles.expandingBlock}>
            <h3>Special Requests (optional)</h3>
            <img src={expandNotes ? CrossIcon : PlusIcon} alt="Toggle notes text area" />
          </div>
          {expandNotes && 
          <div>
            <textarea onChange={(e) => setNotes(e.target.value)} id="notes" name="notes" rows={4} cols={50}></textarea>
          </div>}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <Checkbox
            id="cancellation-policy"
            label={<span>I accept the <button className="button__link" onClick={() => setShowCancellationPolicy(true)}>Cancellation Policy</button></span>}
            checked={checkbox1}
            onChange={(e) => setCheckbox1(e)}
            className={styles.checkbox}
          />
        </div>

        <button onClick={handleBooking} className={`button ${isPayNowDisabled ? 'disabled' : ''}`} disabled={isPayNowDisabled}>
          {isConfirmBookingPending || isPurchasePending ? <Spinner size={20} /> : "Book Now"}
        </button>
        {confirmBookingError && <p className={styles.error}>Error: {confirmBookingError.errorMessages[0]}.</p>}
      </div>

      <AddPaymentMethodModal onClose={() => setShowAddPaymentMethodModal(false)} isOpen={showAddPaymentMethodModal} />

      <Modal isOpen={showCancellationPolicy} onClose={() => setShowCancellationPolicy(false)} hideCloseButton>
        <div className={styles.cancellationModal}>
          <h2>Cancellation Policy</h2>
          <p>We respectfully request at least 12 hours notice if you need to cancel or rebook your massage appointment. We charge 50% of the reservation made to the credit card on file to compensate our staff for missed wages when there is a last minute cancellation.</p>
          <p>If you miss your appointment or arrive 15 minutes past your scheduled service start time, the service is automatically canceled and the full price of the scheduled service is charged to the credit card on file. To ensure a relaxing experience, and get the full benefits of the massage, we advise guests to arrive on time (or early) for all scheduled appointments.</p>
          <button onClick={() => setShowCancellationPolicy(false)} className="button__underline">CLOSE</button>
        </div>
      </Modal>
      
      <Modal isOpen={showPaymentMethodModal} onClose={() => setShowPaymentMethodModal(false)}>
        <PaymentMethodModal
          onClose={() => setShowPaymentMethodModal(false)}
          creditCards={creditCards?.data || []}
          addPaymentMethod={() => setShowAddPaymentMethodModal(true)}
          selectedCard={selectedCard}
          onPaymentMethodChange={setSelectedCard}
        />
      </Modal>
      <Modal className={styles.iframeModal} isOpen={openIframeModal} onClose={() => setOpenIframeModal(false)}>
        {isCreateMembershipPending ? (
          <Spinner />
        ) : !createMembershipError && isCardVerificationRequired && (
          <iframe
            src={createMembershipData?.data.hostedPaymentUri}
            title="Purchase Membership"
            className={styles.iframe}
          />
        )}
      </Modal>
      <Modal isOpen={showMembershipTermsModal} onClose={() => setShowMembershipTermsModal(false)}>
        <MembershipTermsModal membershipId={membershipToPurchase?.zenotiId || ''} boutiqueId={boutiqueId || ''} />
      </Modal >
    </div>
  );
};

export default BookingCheckout;
