
type GTMEvent = {
  [key: string]: any;
};

declare global {
  interface Window {
    dataLayer: GTMEvent[];
  }
}

export const pushToDataLayer = (event: GTMEvent): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({...event, environment: 'website'});
  } else {
    console.warn("GTM dataLayer is not initialized.");
  }
};