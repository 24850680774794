import styles from '../styles/components/PaymentMethodModal.module.scss'
import { DateTime } from 'luxon';
import { CreditCard } from '../types/payment';
import { useDeletePaymentProfile } from '../hooks/usePaymentProfile';
import { useToast } from './core/ToastManager';
import Modal from './core/Modal';
import EditBillingAddressModal from './EditBillingAddressModal';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  onClose: () => void;
  creditCards: CreditCard[];
  addPaymentMethod: () => void;
  onPaymentMethodChange?: (creditCard: CreditCard) => void;
  selectedCard?: CreditCard;
}

const isCardExpired = (expDate: string) => {
  const [month, year] = expDate.split('/');
  const expirationDate = DateTime.local(parseInt(year), parseInt(month)).endOf('month');
  const today = DateTime.local();

  return expirationDate < today;
};

export const PaymentMethodModal:React.FC<Props> = ({ 
  onClose,
  creditCards,
  addPaymentMethod,
  onPaymentMethodChange,
  selectedCard
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { addToast } = useToast();
  const { mutate: deleteProfile, isPending } = useDeletePaymentProfile();
  const queryClient = useQueryClient();

  const handleDelete = (accountId: string) => {
    deleteProfile(accountId, {
      onSuccess: () => {
        addToast('Payment method deleted successfully', 'success');
        // invalidate query to fetch updated credit cards list
        queryClient.invalidateQueries({ queryKey: ['creditCards'] });
        onClose();
      },
      onError: (error) => {
        addToast(error.errorMessages[0] || 'Error deleting payment method', 'error', 4000);
        onClose();
      }
    });
  } 

  const handleEdit = () => {
    setIsEditModalOpen(true);
  }

  const handleCardSelection = (creditCard: CreditCard) => {
    if (onPaymentMethodChange) {
      onPaymentMethodChange(creditCard)
    }
  }
  
  return (
    <div className={styles.paymentMethodModal}>
      <h2>Manage Payment Method</h2>
      <div className={styles.cards}>
        {creditCards.map((card, index) => {
          const { identifier, expiryOn, accountId, paymentAccountId, processorId } = card
          const isExpired = isCardExpired(expiryOn)
          const isDefault = index === 0
          const expiryDate = DateTime.fromFormat(card.expiryOn || '', 'M/yyyy').toFormat('MM/yy')
          const isSelected = selectedCard?.accountId === accountId || (!selectedCard && isDefault)
          return (
            <div
              key={accountId}
              className={`${styles.paymentMethodCard} ${isDefault ? styles.defaultMethod : ''} ${isSelected ? styles.selectedCard : ''}`}
              onClick={() => handleCardSelection(card)}
              >
              {isDefault && !isExpired && <div className={styles.defaultBadge}>Default</div>}
              <p className={styles.cardNumber}>{identifier}</p>
              <p className={styles.expDate}>Exp date {expiryDate}</p>
              <div className={styles.actions}>
                {isExpired && <button disabled className={`button inverted ${styles.expired}`}>Expired</button>}
                <button onClick={handleEdit} className={`button__link ${styles.button}`}>EDIT</button>
                <button onClick={() => handleDelete(paymentAccountId)} className={`button__link ${styles.button}`}>REMOVE</button>
              </div>
              <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                <>
                  <h2>Update Your Billing Zip Code</h2>
                  <EditBillingAddressModal id={accountId} processorId={processorId} onClose={() => setIsEditModalOpen(false)}/>
                </>
              </Modal>
            </div>
          )
        }
        )}
      </div>
      <div className={styles.separator}></div>
      <h3>Add a New Card</h3>
      <p className={styles.addCardDescription}>This credit card will be used by default for billing.</p>
      <button onClick={addPaymentMethod} className={`button ${styles.newCardButton}`}>Add a new card</button>
    </div>
  )
}
