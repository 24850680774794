import { useEffect, useState } from 'react';
import styles from '../styles/components/BookingFlowAppointment.module.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PreferencesIcon from '../assets/icons/preferences.svg';
import CustomSelectDropdown from './core/CustomSelectDropdown';
import { useCreateGuests, useCreateServiceBooking, useGetTherapistAvailabilities } from '../hooks/useBookings';
import { Guest, GuestSelection, UserOptions } from '../pages/Booking';
import { BookingSlot, Therapist } from '../types/bookings';
import Spinner from './core/Spinner';
import { useToast } from './core/ToastManager';
import { DateTime } from 'luxon';
import { formatPhoneNumber, normalizePhoneNumber } from '../utils/formatPhone';
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import { pushToDataLayer } from '../utils/tracking';
import { useNavigate } from 'react-router-dom';
import { useBoutiques } from '../hooks/useBoutiques';
import { Boutique } from '../types/boutiques';

interface Props {
  boutiqueId: string;
  boutiquePhone: string;
  userSelections: GuestSelection;
  userOptions: UserOptions;
  guestsSelections?: GuestSelection[];
  guestsInfo?: Guest[];
  hostZenotiId: string;
  therapists: Therapist[];
  isLoadingTherapists: boolean;
  setCheckoutLink: (link: string) => void
  setGuestsSelections?: (selections: GuestSelection[]) => void
  setUserSelections?: (selections: GuestSelection) => void
  preselectedTimeSlot?: string
  addedExtraEnhancements?: boolean
  checkoutLink?: string
  setBoutiqueWithinRadius: (boutique: Boutique) => void
  setShowLocationSearch: (show: boolean) => void
}

interface GuestsTherapists {
  [guestId: string]: {
    id?: string;
    therapist?: Therapist | null;
    gender?: string
  }
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const genders = [
  { value: 'any', label: 'Any' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const BookingFlowAppointment: React.FC<Props> = ({
  boutiqueId,
  boutiquePhone,
  userSelections,
  guestsSelections,
  userOptions,
  guestsInfo,
  hostZenotiId,
  therapists,
  isLoadingTherapists,
  setCheckoutLink,
  setUserSelections,
  setGuestsSelections,
  preselectedTimeSlot,
  addedExtraEnhancements,
  checkoutLink,
  setBoutiqueWithinRadius,
  setShowLocationSearch
}) => {
  const preselectedDate = preselectedTimeSlot ? new Date(preselectedTimeSlot) : new Date();
  const [dateValue, onDateChange] = useState<Value>(new Date(preselectedDate));
  const [expandedTherapistPreference, setExpandedTherapistPreference] = useState<string[]>([]);
  const navigate = useNavigate();
  const {data: boutiques} = useBoutiques({page: 1, perPage: 100});
  const selectedBoutique = boutiques?.data.boutiques.find(boutique => boutique.id === boutiqueId);
  const nearbyLocation = boutiques?.data.boutiques && selectedBoutique ? findNearbyLocation(selectedBoutique, boutiques?.data.boutiques) : null;

  function findNearbyLocation(
    selectedLocation: Boutique,
    locations: Boutique[],
    maxDistanceMiles: number = 10
  ): Boutique | null {
    const EARTH_RADIUS_MILES = 3958.8;
  
    const haversineDistance = (
      lat1: number,
      lon1: number,
      lat2: number,
      lon2: number
    ): number => {
      const toRadians = (degrees: number) => (degrees * Math.PI) / 180;
      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);
  
      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return EARTH_RADIUS_MILES * c;
    };
  
    const { lat: selectedLat, lng: selectedLng } = selectedLocation;
  
    // Find the first location within the maximum distance
    for (const location of locations) {
      const { lat, lng } = location;
      const distance = haversineDistance(selectedLat, selectedLng, lat, lng);
      if (distance <= maxDistanceMiles && location.id !== selectedLocation.id) {
        return location;
      }
    }
  
    return null;
  }
  

  const initialGuestsTherapists: GuestsTherapists = {
    host: {
      id: 'host',
      therapist: null,
      gender: userOptions.gender || 'any',
    },
  };

  guestsInfo?.forEach((guest, index) => {
    initialGuestsTherapists[`guest-${index}`] = {
      id: `guest-${index}`,
      therapist: null,
      gender: guest.gender || 'any',
    };
  });

  const [guestsTherapists, setGuestsTherapists] = useState<GuestsTherapists>(initialGuestsTherapists);
  const [slots, setSlots] = useState<BookingSlot[]>([])
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(preselectedTimeSlot || '')
  const userAddOnsIds = userSelections?.enhancements?.map(addOn => {
    return addOn.zenotiId
  })
  const encodedUserAddOnIds = encodeURIComponent(JSON.stringify(userAddOnsIds));

  const { mutate: createGuests, data: guestsIdsResponse } = useCreateGuests(
    (data) => {
      handleCreateServiceBooking(data.guestIds)
    },
    (error) => {
      addToast(`Failed to add guests: ${error.errorMessages[0]}`, 'error');
    }
  );


const {
  mutate: createServiceBookingHost,
  data: serviceBookingDataHost,
  isPending: isCreateServiceBookingPendingHost,
} = useCreateServiceBooking(
  (data) => {
    setSlots(data.slots);
  },
  (error) => {
    setSlots([]);
    addToast(error.errorMessages[0] || 'Failed to create booking', 'error');
  }
);

const {
  mutate: createServiceBookingGuest,
  data: serviceBookingDataGuest,
  isPending: isCreateServiceBookingPendingGuest,
} = useGetTherapistAvailabilities(
  (data) => {
    setSlots(data.slots);
  },
  (error) => {
    setSlots([]);
    addToast(error.errorMessages[0] || 'Failed to create booking', 'error');
  }
);

const createServiceBooking = hostZenotiId
  ? createServiceBookingHost
  : createServiceBookingGuest;

const isCreateServiceBookingPending = hostZenotiId
  ? isCreateServiceBookingPendingHost
  : isCreateServiceBookingPendingGuest;

const serviceBookingData = hostZenotiId
  ? serviceBookingDataHost
  : serviceBookingDataGuest;


  const toggleTherapistDetails = (id: string) => {
    setExpandedTherapistPreference((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const handleCreateServiceBooking = (guestIds: string[]) => {
    const guests = guestsInfo?.map((guest, index) => ({
      zenotiId: guestIds[index],
      serviceId: guestsSelections?.[index]?.massage?.zenotiId || '',
      therapistId: guestsTherapists[`guest-${index}`].therapist?.zenotiId || '',
      therapistGender: guestsTherapists[`guest-${index}`].gender || 'any',
      addOnIds: guestsSelections?.[index]?.enhancements?.map(addOn => addOn.zenotiId) || []
    })) || [];

    // Add the host to the beginning of the array
    guests.unshift({
      zenotiId: hostZenotiId,
      serviceId: userSelections?.massage?.zenotiId || '',
      therapistId: guestsTherapists['host'].therapist?.zenotiId || '',
      therapistGender: guestsTherapists['host'].gender || 'any',
      addOnIds: userAddOnsIds || []
    });

    createServiceBooking({
      boutiqueId,
      data: {
        date: dateValue instanceof Date ? dateValue.toISOString() : '',
        guests
      }
    });
  };

  const { addToast } = useToast()
  const today = new Date();
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(today.getMonth() + 3);

  const isTherapistLocked = (therapistId: string, guestId: string): boolean => {
    return Object.entries(guestsTherapists).some(([key, value]) => {
      return key !== guestId && value.therapist?.zenotiId === therapistId;
    });
  };

  // Handle therapist selection
  const handleTherapistChange = (guestId: string, therapistId: string | null, guestIndex?: string) => {
    const therapist = therapists?.find(t => t.zenotiId === therapistId) || null;
    const newTherapist = guestsTherapists[guestId].therapist?.zenotiId === therapistId ? null : therapist;
  
    setGuestsTherapists(prevState => ({
      ...prevState,
      [guestId]: {
        ...prevState[guestId],
        therapist: newTherapist,
      },
    }));

    if (setGuestsSelections && guestIndex) {
      const updatedGuestsSelections = guestsSelections?.map((guestSelection, index) => {
        if (guestId === `guest-${index}`) {
          const currentTherapist = guestSelection?.therapist?.zenotiId;
          const newTherapist =
            currentTherapist === therapistId
              ? null
              : therapists?.find((t) => t.zenotiId === therapistId) || null;
  
          return {
            ...guestSelection,
            therapist: newTherapist,
          };
  
        }
        return guestSelection;
      });
      setGuestsSelections(updatedGuestsSelections || []);
    }

    if (guestId === 'host' && setUserSelections) {
      const currentTherapist = userSelections?.therapist?.zenotiId
      const newTherapist = currentTherapist === therapistId ? null : therapists?.find(t => t.zenotiId === therapistId) || null;
      setUserSelections({
        ...userSelections,
        therapist: newTherapist
      });
    }
  };

  const getFilteredTherapists = (guestId: string) => {
    const selectedGender = guestsTherapists[guestId]?.gender || 'any';
  
    return therapists
      .filter((therapist) => {
        if (selectedGender === 'any' || selectedGender === 'other') return true;
        return therapist.gender.toLowerCase() === selectedGender.toLowerCase();
      })
      .map((therapist) => ({
        value: therapist.zenotiId,
        label: therapist.nickName,
        disabled: isTherapistLocked(therapist.zenotiId, guestId),
      })) || [];
  };

  useEffect(() => {
    if (guestsInfo && guestsInfo.length > 0) {
      const updatedGuests = guestsInfo.map(guest => {
        return {
          firstName: guest.firstName,
          lastName: guest.lastName,
          email: guest.email,
          phone: normalizePhoneNumber(guest.phone),
          gender: guest.gender
        }
      })
      createGuests({
        boutiqueId,
        data: { guests: updatedGuests }
      });
    } else {
      createServiceBooking({
        boutiqueId,
        data: {
          date: dateValue instanceof Date ? dateValue.toISOString() : '',
          guests: [
           {
            zenotiId: hostZenotiId,
            serviceId: userSelections?.massage?.zenotiId || '',
            therapistId: guestsTherapists['host'].therapist?.zenotiId || '',
            therapistGender: guestsTherapists['host'].gender || 'any',
            addOnIds: userAddOnsIds || []
          }
          ]
        }
      });
    }
  }, [guestsInfo, boutiqueId, createGuests, guestsTherapists, dateValue, addedExtraEnhancements]);

  useEffect(() => {
    if (selectedTimeSlot && userSelections.massage?.zenotiId) {
      // Create an array of guest data if guests exist
      const guestsData = guestsSelections?.map((guestSelection, index) => ({
        addOnIds: guestSelection?.enhancements?.map(addOn => addOn.zenotiId) || [],
        name: `${guestsInfo?.[index]?.firstName}`,
        zenotiId: guestsIdsResponse && guestsIdsResponse.guestIds?.[index],
        serviceId: guestSelection?.massage?.zenotiId || '',
        therapistId: guestsTherapists[`guest-${index}`].therapist?.zenotiId,
        therapistGender: guestsInfo?.[index]?.gender ? guestsInfo?.[index]?.gender : guestsTherapists[`guest-${index}`].gender
      })) || [];
  
      // Encode guests data only if there are guests
      const guestsQueryParam = guestsData.length > 0 
        ? `&guests=${encodeURIComponent(JSON.stringify(guestsData))}` 
        : '';
  
        let bookingId = 'null'
        if (serviceBookingData?.zenotiBookingId) {
          bookingId = serviceBookingData?.zenotiBookingId
        }

      const createMembershipQuery = userSelections.createMembership ? `&createMembership=true` : '';
      // Set the checkout link with conditionally added guests data
      if ((bookingId && bookingId !== 'null') || !hostZenotiId) {
        setCheckoutLink(
          `/booking-checkout/${boutiqueId}/${bookingId}/${userSelections.massage?.zenotiId}` +
          `?time=${selectedTimeSlot}&addOns=${encodedUserAddOnIds}&therapistId=${guestsTherapists.host?.therapist?.zenotiId}&gender=${userOptions.gender || guestsTherapists['host'].gender}${guestsQueryParam}${createMembershipQuery}`
        );
      }
    }

    return () => setCheckoutLink('')
  }, [selectedTimeSlot, guestsTherapists, userSelections, guestsSelections, serviceBookingData, addedExtraEnhancements]);

  useEffect(() => {
    if (
      addedExtraEnhancements && 
      !isCreateServiceBookingPending &&
      checkoutLink && 
      (serviceBookingData?.zenotiBookingId || !hostZenotiId)
    ) {
      navigate(checkoutLink);
    }
  }, [
    addedExtraEnhancements, 
    isCreateServiceBookingPendingHost, 
    isCreateServiceBookingPendingGuest, 
    checkoutLink,
    serviceBookingData
  ]);

  const handleShowNearbyLocation = () => {
    if (nearbyLocation) {
      setBoutiqueWithinRadius(nearbyLocation);
      setShowLocationSearch(true);
    }
  }
  
  return (
    <div className={styles.appointment}>
      <div className={styles.leftCol}>
        <Calendar
          onChange={(value) => {
            onDateChange(value)
            pushToDataLayer({
              event: 'dateTimeSelection',
              workflowName: 'booking',
              action: 'buttonClick',
            })
          }}
          value={dateValue}
          className={styles.calendar}
          tileClassName={styles.tile}
          formatShortWeekday={(locale, date) =>
            date.toLocaleDateString(locale, { weekday: 'narrow' })
          }
          formatMonth={(locale, date) =>
            date.toLocaleDateString(locale, { month: 'short' })
          }
          calendarType="gregory"
          minDate={today}
          maxDate={threeMonthsFromNow}
          next2Label={null}
          prev2Label={null}
        />
        <div className={styles.preferencesHeader}>
          <img src={PreferencesIcon} alt="preferences" />
          <p>Therapist Preferences</p>
        </div>
        {guestsInfo && guestsInfo?.length > 0 && <div 
          onClick={() => toggleTherapistDetails('host')}
          className={`${styles.expandingGuestBlock} ${expandedTherapistPreference.includes('host') ? styles.expanded : ''}`}
          >
          <p className={styles.guestName}>For You</p>
          <div className={styles.expandingContentRight}>
            <p>
            {
              guestsTherapists['host'].therapist
                ? guestsTherapists['host'].therapist?.nickName
                : guestsTherapists['host'].gender && guestsTherapists['host'].gender !== 'any'
                ? `Any ${guestsTherapists['host'].gender.charAt(0).toUpperCase() + guestsTherapists['host'].gender.slice(1)} Therapist`
                : 'Any Therapist'
            }
            </p>
            <img src={ArrowDownIcon} alt="Toggle therapist details" />
          </div>
        </div>}
        {(expandedTherapistPreference.includes('host') || guestsInfo?.length  === 0) && (
          <div>
            <CustomSelectDropdown
              label="Gender"
              onChange={(gender) => {
                setGuestsTherapists(prevState => ({
                  ...prevState,
                  host: {
                    ...prevState['host'],
                    therapist: null,
                    gender,
                  },
                }))
                pushToDataLayer({
                  event: 'therapistGender',
                  workflowName: 'booking',
                  action: 'buttonClick',
                })
              }
              }
              options={genders}
              selectedOption={guestsTherapists['host']?.gender || 'any'}
              disabled={!!userOptions.gender}
              disabledMessage='Minor guests can only select same gender therapist'
            />
            <CustomSelectDropdown
              label="Therapist"
              onChange={(therapistId) => {
                handleTherapistChange('host', therapistId)
                pushToDataLayer({
                  event: 'therapistSelection',
                  workflowName: 'booking',
                  action: 'buttonClick',
                })
              }}
              options={getFilteredTherapists('host') || []}
              selectedOption={guestsTherapists['host']?.therapist?.zenotiId || ''}
              className={styles.therapistsSelect}
              optionSeparator
              disabledMessage='This therapist is already selected by another guest'
            />
            {isLoadingTherapists && <Spinner />}
          </div>
          )
        }
        {guestsInfo && guestsInfo.length > 0 && guestsInfo.map((guest, index) => (
          <div key={`guest-${index}`}>
            <div
              onClick={() => toggleTherapistDetails(`guest-${index}`)}
              className={`${styles.expandingGuestBlock} ${expandedTherapistPreference.includes(`guest-${index}`) ? styles.expanded : ''}`}
            >
              <p className={styles.guestName}>For {guest.firstName}</p>
              <div className={styles.expandingContentRight}>
                <p>
                {
                  guestsTherapists[`guest-${index}`].therapist
                    ? guestsTherapists[`guest-${index}`].therapist?.nickName
                    : guestsTherapists[`guest-${index}`].gender && guestsTherapists[`guest-${index}`].gender !== 'any'
                    ? `${(guestsTherapists[`guest-${index}`]?.gender ?? 'any').charAt(0).toUpperCase() + (guestsTherapists[`guest-${index}`]?.gender ?? 'any').slice(1)} Therapist`
                    : 'Any Therapist'
                }
                </p>
                <img src={ArrowDownIcon} alt="Toggle therapist details" />
              </div>
            </div>
            {expandedTherapistPreference.includes(`guest-${index}`) && (
              <>
                <CustomSelectDropdown
                  label="Gender"
                  onChange={(gender) =>
                    setGuestsTherapists(prevState => ({
                      ...prevState,
                      [`guest-${index}`]: {
                        ...prevState[`guest-${index}`],
                        therapist: null,
                        gender,
                      },
                    }))
                  }
                  options={genders}
                  selectedOption={guestsTherapists[`guest-${index}`]?.gender || 'any'}
                  disabled={!!guest.gender}
                  disabledMessage='Minor guests can only select same gender therapist'
                />
                <CustomSelectDropdown
                  label="Therapist"
                  onChange={(therapistId) => handleTherapistChange(`guest-${index}`, therapistId, index.toString())}
                  options={getFilteredTherapists(`guest-${index}`) || []}
                  selectedOption={guestsTherapists[`guest-${index}`]?.therapist?.zenotiId || ''}
                  className={styles.therapistsSelect}
                  optionSeparator
                  disabledMessage='This therapist is already selected by another guest'
                />
              </>
            )}
          </div>
        ))}

      </div>
      <div className={styles.rightCol}>
        <p>{Object.entries(guestsTherapists).map(([guestId, details]) => (
          <span key={guestId}>
          {details.therapist?.nickName 
            ? details.therapist.nickName 
            : details.gender && details.gender !== 'any'
            ? `Any ${details.gender.charAt(0).toUpperCase() + details.gender.slice(1)} Therapist` 
            : 'Any Therapist'}
          {', '}
          </span>
        ))}</p>
        <div className={styles.timeSlots}>
          {isCreateServiceBookingPending && <Spinner />}
          {!isCreateServiceBookingPending && slots
            .filter(slot => {
              const selectedDate = dateValue instanceof Date ? dateValue.toISOString() : ''
              const slotDate = DateTime.fromISO(slot.time).toFormat("yyyy-MM-dd");
              return slotDate === DateTime.fromISO(selectedDate).toFormat("yyyy-MM-dd");
            })
            .map((slot, index) => {
              const formattedTime = DateTime.fromISO(slot.time).toFormat("h:mm a");
              return (
                <div
                  className={`${styles.slot} ${selectedTimeSlot === slot.time ? styles.selected : ''}`}
                  key={slot.time + index}
                  onClick={() => {
                    setSelectedTimeSlot(slot.time)
                    pushToDataLayer({
                      event: 'timeSelectionOnly',
                      workflowName: 'booking',
                      action: 'buttonClick',
                    })
                  }}
                >
                  {formattedTime}
                </div>
              );
            })}
        </div>
        <div className={styles.callUs}>
          Not seeing the availability you want? Please call us at {formatPhoneNumber(boutiquePhone)}
        </div>
          {nearbyLocation && <button onClick={handleShowNearbyLocation} className={`button inverted ${styles.nearbyBoutiqueBtn}`}>Check Nearby Boutique for Availability</button>}
      </div>
    </div>
  );
};
