import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchBoutiques, fetchBoutiqueById, fetchBoutiquePromotions, fetchNationalPromotions, fetchPromoDetails, fetchMembershipPromoDetails, fetchSignInPromotions } from '../api/boutiques';
import { BoutiquesResponse, Boutique, BoutiquesRequestParams, PromotionResponse, PromoDetailsResponse, SignInPromotionResponse } from '../types/boutiques';
import { ApiErrorResponse } from '../types/api';


export const useBoutiques = (options: BoutiquesRequestParams = {}) => {
  return useQuery<BoutiquesResponse, ApiErrorResponse>({
    queryKey: ['boutiques', options],
    queryFn: () => fetchBoutiques(options)
  });
};

export const useBoutique = (id: string) => {
  return useQuery<{ data: Boutique }, ApiErrorResponse>({
    queryKey: ['boutique', id],
    queryFn: () => fetchBoutiqueById(id),
    enabled: !!id && id !== 'null'
  });
};

export const useBoutiquePromotions = (boutiqueId: string, promoType: string) => {
  return useQuery<PromotionResponse, ApiErrorResponse>({
    queryKey: ['boutiquePromotions', boutiqueId],
    queryFn: () => fetchBoutiquePromotions(boutiqueId, promoType),
    enabled: !!boutiqueId && boutiqueId !== 'null'
  });
}

export const useNationalPromotions = (promoType: string) => {
  return useQuery<PromotionResponse, ApiErrorResponse>({
    queryKey: ['nationalPromotions'],
    queryFn: () => fetchNationalPromotions(promoType)
  });
}

export const useSignInPromotions = () => {
  return useQuery<SignInPromotionResponse, ApiErrorResponse>({
    queryKey: ['signInPromotions'],
    queryFn: () => fetchSignInPromotions()
  });
}

export const usePromoDetails = (
  onSuccess?: (data: PromoDetailsResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<PromoDetailsResponse, ApiErrorResponse, { promoCode: string, boutiqueId: string, serviceId: string, bookingDate?: string, addOnIds?: string[]}>({
    mutationFn: ({promoCode, boutiqueId, serviceId, bookingDate, addOnIds}) => fetchPromoDetails(promoCode, boutiqueId, serviceId, bookingDate, addOnIds),
    onSuccess,
    onError,
  });
};

export const useMembershipPromoDetails = (
  onSuccess?: (data: PromoDetailsResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<PromoDetailsResponse, ApiErrorResponse, { promoCode: string, boutiqueId: string}>({
    mutationFn: ({promoCode, boutiqueId}) => fetchMembershipPromoDetails(promoCode, boutiqueId),
    onSuccess,
    onError,
  });
};
