import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createInvoice, closeInvoice, closeGiftCardInvoice, applyPromoToInvoice } from '../api/purchase'; 
import { InvoiceRequest, InvoiceResponse, GiftCardCloseInvoiceRequest, ApplyPromoToInvoiceResponse } from '../types/purchase'; 
import { ApiErrorResponse } from '../types/api'; 


export const useCreateInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, InvoiceRequest> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, InvoiceRequest>({
    mutationFn: (data: InvoiceRequest) => createInvoice(data),
    onSuccess, 
    onError, 
  });
};

export const useCloseInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, { invoiceId: string; giftCardPurchase?: boolean, boutiqueId?: string }> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, { invoiceId: string; giftCardPurchase?: boolean, boutiqueId?: string  }>({
    mutationFn: ({ invoiceId, giftCardPurchase, boutiqueId }) => closeInvoice(invoiceId, giftCardPurchase, boutiqueId),
    onSuccess,
    onError,
  });
};

export const useCloseGiftCardInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, { invoiceId: string; data: GiftCardCloseInvoiceRequest }> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, { invoiceId: string; data: GiftCardCloseInvoiceRequest }>({
    mutationFn: ({ invoiceId, data }) => closeGiftCardInvoice(invoiceId, data),
    onSuccess,
    onError,
  });
};

export const useApplyPromoToInvoice = (
  onSuccess?: (data: ApplyPromoToInvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<ApplyPromoToInvoiceResponse, ApiErrorResponse, { invoiceId: string; offerCode: string; boutiqueId: string }> => {
  return useMutation<ApplyPromoToInvoiceResponse, ApiErrorResponse, { invoiceId: string; offerCode: string; boutiqueId: string }>({
    mutationFn: ({ invoiceId, offerCode, boutiqueId }) => applyPromoToInvoice(invoiceId, offerCode, boutiqueId),
    onSuccess,
    onError,
  });
};