import { InputHTMLAttributes, ReactNode, useState } from 'react';
import styles from '../../styles/components/Input.module.scss';
import HidePasswordIcon from '../../assets/icons/hide-password.svg';
import ShowPasswordIcon from '../../assets/icons/show-password.svg';
import InputFeedback from './InputFeedback';

interface InputProps {
  id: string;
  label: string;
  className?: string;
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  error?: string | ReactNode;
  success?: string;
  apiError?: boolean;
  mask?: string;
  hasValue?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const Input: React.FC<InputProps> = ({
  id,
  label,
  className,
  inputProps,
  error,
  success,
  apiError,
  mask,
  hasValue,
  onFocus,
  onBlur
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus){
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur){
      onBlur();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange?.(e); 
  };

  return (
    <div
      className={`
        ${styles.input} 
        ${className || ''} 
        ${error ? styles.entryError : ''} 
        ${success ? styles.entrySuccess : ''}`}
    >
      <input
        id={id}
        className={inputProps.type === 'password' ? styles.passwordInput : ''}
        {...inputProps}
        type={inputProps.type === 'password' ? (isPasswordVisible ? 'text' : 'password') : inputProps.type}
        placeholder=" "
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
      />
      <label className={styles.placeholder} htmlFor={id}>
        {label}
      </label>
      {mask && isFocused && !hasValue && <span className={styles.mask}>{mask}</span>}
      {inputProps.type === 'password' && (
        <button
          type="button"
          className={styles.hidePassword}
          onClick={togglePasswordVisibility}
        >
          <img
            src={isPasswordVisible ? HidePasswordIcon : ShowPasswordIcon}
            alt="Toggle password visibility"
          />
        </button>
      )}
      {/* the span below can be used for nice animation on input focus, for now it is not used */}
      {/* <span className={styles.underline}></span> */}
      {error && <InputFeedback message={error} variant="error" apiError={apiError} />}
      {!error && success && <InputFeedback message={success} variant="success" />}
    </div>
  );
};
