import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import { useState } from 'react';
// styles
import styles from '../styles/components/MainMenu.module.scss';
// media assets
import Logo from '../assets/logos/logo-blue.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user-account.svg';
import { ReactComponent as CartIcon } from '../assets/icons/shopping-bag.svg';

interface MenuLinkItem extends NavLinkProps {
  label: string;
  isHidden?: boolean;
  childLinks?: MenuLinkItem[];
  isExternal?: boolean;
  url?: string;
}

export default function MainMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const marketingWebsiteUrl = process.env.REACT_APP_MARKETING_WEBSITE_URL;
  const links: MenuLinkItem[] = [
    {
      label: 'Menu',
      to: '',
      url: `${marketingWebsiteUrl}/the-menu`,
    },
    {
      label: 'Locations',
      to: '',
      url: `${marketingWebsiteUrl}/boutiques`,
    },
    {
      label: 'Membership',
      to: '/memberships',
    },
    {
      label: 'Gift Cards',
      to: `${marketingWebsiteUrl}/giftcards`,
    },
    {
      label: 'Shop',
      to: '',
      url: 'https://thenowmassage.com/collections/all',
    },
    {
      label: 'Own a Now',
      to: '',
      url: `https://franchising.thenowmassage.com/`,
    }
  ];

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div className={styles.mainMenu}>
      <div className={`${styles.mainMenuContainer} container`}>
        <Link to="https://new.thenowmassage.com/">
          <img src={Logo} alt="The Now Logo" />
        </Link>
        <div className={styles.mainMenuLinksWrapper}>
          <nav>
            <ul className={`${styles.mainMenuNavLinks} ${isMenuOpen ? styles.active : ''}`}>
              {links.map((link, index) => (
                link.url ? (
                  <li key={index}>
                    <Link 
                      to={link.url}
                      className={styles.navLink}
                      rel="noreferrer"
                    >
                      {link.label}
                    </Link>
                  </li>
                ) : (
                  <li key={index}>
                    <NavLink 
                      to={link.to}
                      className={({ isActive }) =>
                        [
                          styles.navLink,
                          isActive ? styles.active : '',
                          link.isHidden ? styles.hidden : ''
                        ].join(' ')
                      }
                    >
                      {link.label}
                    </NavLink>
                  </li>
                )
              ))}
            </ul>
          </nav>
          <Link className={`button ${styles.button} ${styles.navCta}`} to="/book">book</Link>
          <div className={styles.mainMenuUserLinks}>
            <Link to="/settings/personal-details"><UserIcon /></Link>
            <Link to="https://thenowmassage.com/collections/all" target="_blank"><CartIcon /></Link>
          </div>
          <button className={`${styles.menuToggle} ${isMenuOpen ? styles.open : ''}`} onClick={toggleMenu} aria-label="Open main menu">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
  );
}
