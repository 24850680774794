import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/BookingCheckout.module.scss';
import AddPaymentMethodModal from '../components/AddPaymentMethodModal';
import Modal from '../components/core/Modal';
import Spinner from '../components/core/Spinner';
import { PaymentMethodModal } from '../components/PaymentMethodModal';
import { useToast } from '../components/core/ToastManager';
import { useCreditCards } from '../hooks/useCreditCards';
import { useBoutique } from '../hooks/useBoutiques';
import { useCloseGiftCardInvoice } from '../hooks/useInvoice';
import { useCreateGiftCard, useCreateCustomGiftCard } from '../hooks/useGiftCards';
import GiftCardDetails from '../components/GiftCardDetails';
import ErrorScreen from '../components/core/ErrorMessages';
import { useCreateUserMembership, useUserProfile } from '../hooks/useUserProfile';
import { pushToDataLayer } from '../utils/tracking';

export const CustomGiftCardCheckout = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { boutiqueId } = useParams();
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message')
  const recipientName = searchParams.get('recipient_name')
  const recipientEmail = searchParams.get('recipient_email')
  const giftCardValue = searchParams.get('custom_amount')
  const promoCodeSecondaryEmail = searchParams.get('promo_code_secondary_email')
  const alternateEmail = searchParams.get('alternate_email')
  const generalPromoValue = Number(searchParams.get('general_promo_value')) || 0
  
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')
  const { data: userProfile, isLoading: userProfileLoading, error: userProfileError } = useUserProfile();
  
  const { mutate: createCustomGiftCard, data: customGiftCardData, isPending: isCreateCustomGiftCardPending, error: createCustomGiftCardError} = useCreateCustomGiftCard(
    (data) => {
      if(data.data.promoValue) {
        setPromotionalValue(data.data.promoValue)
      }
    },
    (error) => {
      addToast(`Error: ${error.errorMessages[0]}`, 'error');
    }
  )

  const [isCardVerificationRequired, setIsCardVerificationRequired] = useState(false);
  const [openIframeModal, setOpenIframeModal] = useState(false);
  const [promotionalValue, setPromotionalValue] = useState(0);

  const { mutate: createGiftCard, data: giftCardData, isPending: isCreateGiftCardPending, error: createGiftCardError} = useCreateGiftCard(boutiqueId || '',
    (data) => {
      if ((creditCards?.data[0].accountId)) {
        purchaseGiftCard({
          redirectUri: `${process.env.REACT_APP_WEBSITE_URL}/purchase-success`,
          invoiceId: data.data.invoiceId,
          accountId: selectedCard?.accountId || creditCards?.data[0].accountId,
          boutiqueId: boutiqueId || '',
        })
      } else {
        addToast(`Purchase failed: No credit card on file. Please add a credit card to proceed.`, 'warning');
      }
    },
    (error) => {
      addToast(`Error: ${error.errorMessages[0]}`, 'error');
    }
  )

  const { mutate: closeGiftCardInvoice, isPending: isCloseInvoicePending, error: closeInvoiceError } = useCloseGiftCardInvoice(
    (data) => {
      if (data.data.status !== 'Closed') {
        addToast(`Error purchasing gift card`, 'error');
      } else {
        addToast('Purchase successful!', 'success', 3500);
        pushToDataLayer({
          event: 'tnm.purchase',
          workflowName: 'giftCard',
          notes: 'custom gift card amount purchase'
        })
        navigate(`/custom-gift-card-purchase-confirmation/${boutiqueId}?message=${message}&recipientName=${recipientName}&custom_amount=${giftCardValue}&promo_value=${promotionalValue}`);
      }
    },
    (error) => {
      addToast(`Error closing invoice: ${error.errorMessages[0]}`, 'error');
    }
  );

  const { mutate: purchaseGiftCard, data: purchaseGiftCardData, isPending: isPurchaseGiftCardPending, error: purchaseGiftCardError } = useCreateUserMembership(
    (data) => {
      if (!data.data.success) {
        setIsCardVerificationRequired(true);
        setOpenIframeModal(true);
      } else if (data.data.success && giftCardData?.data.invoiceId) {
        const data = {
          giftCardPurchase: true,
          promoValue: promotionalValue,
          ...(boutiqueId && { boutiqueId }),
          ...(alternateEmail && {
            voucherRecipient: {
              alternateRecipient: true,
              email: alternateEmail,
            },
          }),
        };
        closeGiftCardInvoice({invoiceId: giftCardData?.data.invoiceId, data});
      }
    },
    (error) => {
      addToast(`Error purchasing gift card: ${error.errorMessages[0]}`, 'error');
    }
  );

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const { data: creditCards, error: creditCardError, isLoading: isCreditCardLoading } = useCreditCards();
  const [selectedCard, setSelectedCard] = useState(creditCards?.data[0])


  useEffect(() => {
    if ((!boutiqueId || !customGiftCardData?.data.id) && isCreateCustomGiftCardPending) {
      navigate('/dashboard');
    }
  }, [customGiftCardData?.data.id]);

  useEffect(()=>{
    if(boutiqueId && giftCardValue) {
      createCustomGiftCard({
        centerId: boutiqueId,
        value: Number(giftCardValue)
      })
    }
  },[boutiqueId])

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data?.type === 'processComplete' && giftCardData?.data.invoiceId) {
        if (event.data.success) {
          setOpenIframeModal(false);
          const data = {
            giftCardPurchase: true,
            promoValue: promotionalValue,
            ...(boutiqueId && { boutiqueId }),
            ...(alternateEmail && {
              voucherRecipient: {
                alternateRecipient: true,
                email: alternateEmail,
              },
            }),
          };
          closeGiftCardInvoice({invoiceId: giftCardData?.data.invoiceId, data});
        } else {
          addToast(event.data.message, 'error');
        }
      }
    };
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [giftCardData?.data.invoiceId]);

  const isPayNowDisabled = creditCards?.data.length === 0;

  const handleConfirmPurchase = () => {
    if (isPayNowDisabled) return;
    if (customGiftCardData?.data.id && recipientName && recipientEmail && userProfile?.data.firstName && userProfile?.data.email) {
      createGiftCard({
        templateId: customGiftCardData?.data.id,
        message: message || '',
        recepientName: recipientName,
        recepientEmail: recipientEmail,
        senderName: userProfile?.data.firstName,
        senderEmail: userProfile?.data.email,
        promoCodeSecondaryEmail: promoCodeSecondaryEmail || ''
      })
    }
  }

  const isPurchasePending = isCreateGiftCardPending || isCloseInvoicePending || isPurchaseGiftCardPending;

  if (isCreditCardLoading || isLoadingBoutique || isCreateCustomGiftCardPending || userProfileLoading) return <Spinner />;
  if (boutiqueError || createCustomGiftCardError || userProfileError) return <ErrorScreen errors={[boutiqueError ?? undefined, createCustomGiftCardError ?? undefined, userProfileError ?? undefined]} />;

  return (
    <div className={styles.bookingCheckout}>
      <div className={styles.bookingDetails}>
        <h2>Order Details</h2>
        <GiftCardDetails 
          title={`$${giftCardValue} Gift Card`}
          promotionalValue={promotionalValue}
          location={boutique?.data.name || ''}
          message={message || ''}
          recipientName={recipientName || ''}
          recipientEmail={recipientEmail || ''}
          senderName={`${userProfile?.data.firstName} ${userProfile?.data.lastName}`}
          alternateEmail={alternateEmail || ''}
          generalPromoValue = {generalPromoValue}
        />
      </div>

      <div className={styles.paymentDetails}>
        <div className={styles.contentBlock}>
          <h2>Payment Method</h2>
          {isCreditCardLoading ? (
            <Spinner />
          ) : creditCards?.data && creditCards?.data.length > 0 ? (
            <div className={styles.inputWithAction}>
              <p><strong>{selectedCard?.cardLogo || creditCards.data[0].cardLogo}</strong> ending in {selectedCard?.lastFour || creditCards.data[0].lastFour}</p>
              <button className={`button__underline`} onClick={() => setShowPaymentMethodModal(true)}>MANAGE</button>
            </div>
          ) : (
            <button className="button" onClick={() => setShowAddPaymentMethodModal(true)}>Add Payment Method</button>
          )}
          {creditCardError && <p className={styles.error}>Error loading payment methods: {creditCardError.errorMessages[0]}</p>}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <h2>Price Details</h2>
          <div className={styles.priceDetails}>
            <div className={styles.packageInfo}>
              <p>Gift Card</p>
            </div>
            <div className={styles.price}>${giftCardValue}</div>
          </div>
          {promotionalValue > 0 && generalPromoValue > 0 && (
            <p className={styles.promotionalValue}>+ ${promotionalValue} {(promotionalValue / generalPromoValue) > 1 ? "in promotional cards" : "promotional card"}</p>
          )}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <div className={styles.subTotal}>
            <p>Sub Total</p>
            <p>${giftCardValue}</p>
          </div>
          <div className={styles.total}>
            <p><strong>Total</strong></p>
            <p><strong>${giftCardValue}</strong></p>
          </div>
        </div>

        <button onClick={handleConfirmPurchase} className={`button ${isPayNowDisabled ? 'disabled' : ''}`} disabled={isPayNowDisabled}>
          {isPurchasePending ? <Spinner size={20} /> : "Buy Now"}
        </button>
        {closeInvoiceError && <p className={styles.error}>Error purchasing gift card: {closeInvoiceError.errorMessages[0]}.</p>}
      </div>

      <AddPaymentMethodModal onClose={() => setShowAddPaymentMethodModal(false)} isOpen={showAddPaymentMethodModal} />
      
      <Modal isOpen={showPaymentMethodModal} onClose={() => setShowPaymentMethodModal(false)}>
        <PaymentMethodModal
          onClose={() => setShowPaymentMethodModal(false)}
          creditCards={creditCards?.data || []}
          addPaymentMethod={() => setShowAddPaymentMethodModal(true)}
          selectedCard={selectedCard}
          onPaymentMethodChange={setSelectedCard}
        />
      </Modal>

      <Modal className={styles.iframeModal} isOpen={openIframeModal} onClose={() => setOpenIframeModal(false)}>
        {isPurchaseGiftCardPending ? (
          <Spinner />
        ) : !purchaseGiftCardError && isCardVerificationRequired && (
          <iframe
            src={purchaseGiftCardData?.data.hostedPaymentUri}
            title="Purchase Gift Card"
            className={styles.iframe}
          />
        )}
      </Modal>
    </div>
  );
};

export default CustomGiftCardCheckout;
