import React, { useState, useEffect } from 'react';
import ActiveSelectIcon from '../../assets/icons/active-select-filled.svg';
import InactiveSelectIcon from '../../assets/icons/inactive-select.svg';
import styles from '../../styles/components/NestedRadioBox.module.scss';

interface Option {
  label: string;
  value: string;
}

interface CustomRadioBoxProps {
  id: string;
  label: string;
  selectedValue: string;
  onChange: (value: string) => void;
  error?: string;
  className?: string;
  options?: Option[];
  setErrorState?: (id: string, error: boolean) => void;
}

const NestedRadioBox: React.FC<CustomRadioBoxProps> = ({
  id,
  label,
  selectedValue,
  onChange,
  error,
  setErrorState,
  className,
  options = [],
}) => {
  const [isParentChecked, setIsParentChecked] = useState(!!selectedValue);
  const [selectedSubValue, setSelectedSubValue] = useState<string>(selectedValue || '');
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    if (isParentChecked && !selectedSubValue) {
      setLocalError(true);
      setErrorState?.(id, true); // Notify parent about the error
    } else {
      setLocalError(false);
      setErrorState?.(id, false); // Notify parent to clear the error
    }
  }, [isParentChecked, selectedSubValue, id]);

  const handleParentChange = () => {
    if (isParentChecked) {
      setIsParentChecked(false);
      setSelectedSubValue('');
      onChange('');
      setErrorState?.(id, false); // Clear error when parent is deselected
    } else {
      setIsParentChecked(true);
    }
  };

  const handleSubOptionChange = (value: string) => {
    setSelectedSubValue(value);
    onChange(value);
  };

  return (
    <div className={`${styles.radioBoxContainer} ${className || ''}`}>
      {/* Parent Checkbox */}
      <div
        className={`${styles.input} ${isParentChecked ? styles.selected : ''}`}
        onClick={handleParentChange}
      >
        <input
          type="checkbox"
          id={id}
          checked={isParentChecked}
          onChange={handleParentChange}
          className={styles.radioInput}
        />
        <label htmlFor={id} className={styles.radioLabel}>
          {label}
          <img
            src={isParentChecked ? ActiveSelectIcon : InactiveSelectIcon}
            alt={isParentChecked ? 'Selected' : 'Not Selected'}
            className={styles.radioIcon}
          />
        </label>
      </div>

      {/* Child Options */}
      {isParentChecked && options.length > 0 && (
        <div className={styles.subOptions}>
          {options.map((option) => (
            <div key={option.value} className={styles.radioOption}>
              <input
                type="radio"
                id={`${id}-${option.value}`}
                value={option.value}
                checked={selectedSubValue === option.value}
                onChange={() => handleSubOptionChange(option.value)}
                className={styles.radioInputSub}
              />
              <label htmlFor={`${id}-${option.value}`} className={styles.radioLabel}>
                <span>{option.label}</span>
                <img
                  src={
                    selectedSubValue === option.value
                      ? ActiveSelectIcon
                      : InactiveSelectIcon
                  }
                  alt={selectedSubValue === option.value ? 'Selected' : 'Not Selected'}
                  className={styles.radioIcon}
                />
              </label>
            </div>
          ))}
        </div>
      )}

      {(localError || error) && <p className={styles.error}>{error || 'Please select a sub-option.'}</p>}
    </div>
  );
};

export default NestedRadioBox;