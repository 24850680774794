import { useQuery, useMutation, UseMutationResult } from '@tanstack/react-query';
import { fetchGiftCardTemplates, createGiftCard, checkGiftCardBalance, createCustomGiftCard } from '../api/giftCards';
import { GiftCardsResponse, CreateGiftCardRequest, CreateGiftCardResponse, GiftCardBalanceResponse, CreateCustomGiftCardRequest, CreateCustomGiftCardResponse } from '../types/giftCards';
import { ApiErrorResponse } from '../types/api';

export const useGiftCardTemplates = (boutiqueId: string) => {
  return useQuery<GiftCardsResponse, ApiErrorResponse>({
    queryKey: ['giftCardTemplates', boutiqueId],
    queryFn: () => fetchGiftCardTemplates(boutiqueId),
  });
};

export const useCreateGiftCard = (
  boutiqueId: string,
  onSuccess?: (data: CreateGiftCardResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<CreateGiftCardResponse, ApiErrorResponse, CreateGiftCardRequest> => {
  return useMutation<CreateGiftCardResponse, ApiErrorResponse, CreateGiftCardRequest>({
    mutationFn: (data: CreateGiftCardRequest) => createGiftCard(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useCreateCustomGiftCard = (
  onSuccess?: (data: CreateCustomGiftCardResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<CreateCustomGiftCardResponse, ApiErrorResponse, CreateCustomGiftCardRequest> => {
  return useMutation<CreateCustomGiftCardResponse, ApiErrorResponse, CreateCustomGiftCardRequest>({
    mutationFn: (data: CreateCustomGiftCardRequest) => createCustomGiftCard(data),
    onSuccess,
    onError,
  });
};

export const useCheckGiftCardBalance = (
  onSuccess?: (data: GiftCardBalanceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<GiftCardBalanceResponse, ApiErrorResponse, string>({
    mutationFn: (giftCardCode: string) => checkGiftCardBalance(giftCardCode),
    onSuccess,
    onError
  });
};
